import {ReactNode, useEffect, useMemo, useState} from "react";
import {Resolvable, ResolvableId} from "../../../../cdx-models/Resolvable";
import {
  Box,
  Col,
  DSButton,
  DSIconBell,
  DSIconButton,
  DSIconCheck,
  DSIconChevronDown,
  DSIconChevronRight,
  DSIconPencil,
  Row,
  Text,
  css,
} from "@cdx/ds";
import {convoStyles as styles, themedConvoButton} from "./convo.css";
import FromNow, {useAgeColorProps} from "../../../../components/FromNow";
import Comment, {CommentLikeContainer, ConvoLine} from "./Comment";
import {CardId} from "../../../../cdx-models/Card";
import {
  lexicalSerializer,
  useCdxEditorState,
} from "../../../../components/RichTextarea/Lexical/LexicalRichTextProvider";
import {createPersistStore} from "../../../../lib/hooks/usePersistStore";
import useMutation from "../../../../lib/hooks/useMutation";
import CommentForm from "./CommentForm";
import {pluralize} from "../../../../lib/utils";
import {AvatarAction, ConvoAction, getConversationActions} from "./get-convo-actions";
import {ArrowOverlay, DropDownForChild, TooltipForChild, cx, useDelayedTrigger} from "@cdx/common";
import {isHeroCard} from "../../../workflows/workflow-utils";
import ConvoParticipants from "./ConvoParticipants";
import {ResolvableEntry, ResolvableEntryId} from "../../../../cdx-models/ResolvableEntry";
import {ResolvableParticipantHistory} from "../../../../cdx-models/ResolvableParticipantHistory";
import {orderBy} from "lodash";
import {ConvoBag, ResolvableBag, SnoozeUntilLabel, createResolvableBag} from "./convo-utils";
import {hasPermissionToGuardCard} from "../../../../lib/permissions";
import {ResolvableNotification} from "../../../../cdx-models/ResolvableNotification";
import ConvoEditorCommandsPlugin from "../../../conversations/ConvoEditorCommandsPlugin";
import {api} from "../../../../lib/api";
import {Project} from "../../../../cdx-models/Project";
import {hoverGroupContainer} from "@cdx/ds/css/decoration.css";
import {performDoneAction} from "../../performDoneAction";
import {FEATURE_FLAGS, hasFeatureFlag} from "../../../../components/useFeatureFlag";
import {UserId} from "../../../../cdx-models/User";
import ApproveButton, {ApproveReaction} from "./ApproveButton";
import {getNextMorning} from "../../../../lib/date-utils";

const infoByType = {
  comment: {
    title: "Conversation",
  },
  review: {
    title: "Review",
  },
  block: {
    title: "Block",
  },
};

type ConvoHeaderProps = {
  avatarAction: AvatarAction | null;
  resBag: ResolvableBag;
  isPreview?: boolean;
};
export const ConvoHeader = ({resBag, avatarAction, isPreview}: ConvoHeaderProps) => {
  const type = resBag.resolvable.$meta.get("context", "comment");
  const {isOpen, setIsOpen} = resBag;
  const typeInfo = infoByType[type];
  return (
    <Row
      className={styles.mainAsBg}
      minHeight="32px"
      align="center"
      px="12px"
      rounded={8}
      sp="12px"
    >
      {setIsOpen && (
        <DSIconButton
          icon={isOpen ? <DSIconChevronDown /> : <DSIconChevronRight />}
          onClick={() => setIsOpen(!isOpen)}
          variant="tertiary"
          size="sm"
          negatePadding
          className={themedConvoButton}
        />
      )}
      <Text type="label12" as="h2" color="primary">
        {typeInfo.title}
      </Text>
      <ConvoParticipants
        resBag={resBag}
        avatarAction={avatarAction}
        className={css({ml: "auto"})}
      />
    </Row>
  );
};

const StickyConvoHeader = (props: ConvoHeaderProps) => (
  <Box
    absolute
    inset="0"
    style={{paddingLeft: 42, paddingBottom: 200}}
    zIndex={1}
    py="6px"
    pr="6px"
    pointerEvents="none"
  >
    <Box position="sticky" top="4px" pointerEvents="auto">
      <ConvoHeader {...props} />
    </Box>
  </Box>
);

type ConvoContainerProps = {
  children: ReactNode;
  highlighted?: boolean;
  resBag: ResolvableBag;
};
export const ConvoContainer = (props: ConvoContainerProps) => {
  const {children, highlighted, resBag} = props;
  const {resolvable} = resBag;
  const type = resolvable.$meta.get("context", "comment");
  return (
    <Col
      className={cx(styles.themes[type], highlighted && styles.highlighted)}
      rounded={12}
      relative
    >
      {children}
    </Col>
  );
};

const SnoozeOptionsOverlay = ({
  overlayProps,
  meId,
  resId,
  active,
}: {
  overlayProps: any;
  meId: UserId;
  resId: ResolvableId;
  active: boolean;
}) => {
  const close = overlayProps.close as () => void;
  const [doSnooze] = useMutation("notifications", "snoozeResolvables");
  const doSnoozeFor = (date: Date) =>
    doSnooze({resolvableIds: [resId], until: date, userId: meId}).then(close);
  const variant = active ? "secondary" : "primary";

  return (
    <ArrowOverlay bg="white" arrowSize="sm" {...overlayProps}>
      <Col pa="16px" sp="12px">
        {active && (
          <DSButton
            onClick={() =>
              doSnooze({resolvableIds: [resId], until: null, userId: meId}).then(close)
            }
            size="sm"
            variant="primary"
          >
            Clear Reminder
          </DSButton>
        )}
        <Text type="label14">Remind me later:</Text>
        <DSButton onClick={() => doSnoozeFor(getNextMorning())} size="sm" variant={variant}>
          tomorrow
        </DSButton>
        <DSButton onClick={() => doSnoozeFor(getNextMorning(3))} size="sm" variant={variant}>
          3 days
        </DSButton>
        <DSButton onClick={() => doSnoozeFor(getNextMorning(7))} size="sm" variant={variant}>
          1 week
        </DSButton>
        <DSButton onClick={() => doSnoozeFor(getNextMorning(14))} size="sm" variant={variant}>
          2 weeks
        </DSButton>
      </Col>
    </ArrowOverlay>
  );
};

type SnoozeUntilButtonProps = {
  resBag: ResolvableBag;
  resNoti?: ResolvableNotification;
};
const SnoozeUntilButton = ({resBag, resNoti}: SnoozeUntilButtonProps) => {
  const {root, resolvable} = resBag;
  const meId = root.loggedInUser?.$meta.get("id", null)!;
  const resId = resolvable.$meta.get("id", null)!;
  const snoozeUntil = resNoti?.$meta.get("snoozeUntil", null);

  return (
    <DropDownForChild
      setChildProps={({isOpen}: any) => ({
        tooltip: isOpen ? null : "Remind me later",
        active: isOpen,
      })}
      renderOverlay={(overlayProps: any) => (
        <SnoozeOptionsOverlay
          overlayProps={overlayProps}
          meId={meId}
          resId={resId}
          active={Boolean(snoozeUntil)}
        />
      )}
      overlayProps={{placement: "right", distanceFromAnchor: 10}}
    >
      <TooltipForChild tooltip={null}>
        <DSIconButton
          icon={<DSIconBell />}
          variant="secondary"
          size="sm"
          label={snoozeUntil && ((<SnoozeUntilLabel snoozeUntil={snoozeUntil} />) as any as string)}
        />
      </TooltipForChild>
    </DropDownForChild>
  );
};

const commentPersistStore = createPersistStore<Resolvable>({
  elementToKey: (res) => {
    const id = res.$meta.get("id", null);
    return id ? `create-resolvable-entry-${id}` : null;
  },
});

type AddCommentFormProps = {
  resBag: ResolvableBag;
  actions: ConvoAction[];
  resNoti: ResolvableNotification | undefined;
};

const AddCommentForm = ({actions, resBag, resNoti}: AddCommentFormProps) => {
  const {root, card, onReceiveFile, resolvable, canModify, onCardDone} = resBag;
  const [isEmpty, setIsEmpty] = useState(true);
  const contentField = useCdxEditorState({
    initialContent: () =>
      lexicalSerializer.fromJSON(commentPersistStore.getStoredValue(resolvable)) || "",
    contentKey: resolvable.$meta.get("id", null),
    onChangeListeners: {
      isEmpty: setIsEmpty,
    },
  });

  commentPersistStore.usePersistValues(resolvable, () => {
    if (!isEmpty && contentField.getText() !== card.content) {
      return lexicalSerializer.toJSON(contentField);
    }
  });

  const [doComment] = useMutation("resolvables", "comment");

  const handleEntryFormSubmit = () => {
    return doComment({
      resolvableId: resolvable.id,
      content: contentField.getText(),
      authorId: root.loggedInUser.id,
    }).then(() => {
      commentPersistStore.clearStoredValue(resolvable);
      contentField.setText("");
    });
  };

  const commentIfContent = async () => {
    if (!contentField.getText().trim().length) return;
    await handleEntryFormSubmit();
  };

  const cardId = card.cardId as CardId;
  const handleDone = canModify ? () => performDoneAction({cardIds: [cardId], onCardDone}) : null;

  const v2Active = hasFeatureFlag(root.account, FEATURE_FLAGS.notificationV2);

  const meParticipating = resolvable.participants.some(
    (p) => p.$meta.getId("user") === root.loggedInUser.id
  );

  return (
    <>
      <CommentLikeContainer
        resBag={resBag}
        user={root.loggedInUser}
        lineType={actions.length === 0 ? "none" : "entry"}
        pt="12px"
      >
        <CommentForm
          onSubmit={handleEntryFormSubmit}
          content={contentField}
          isEmpty={isEmpty}
          onReceiveFile={onReceiveFile}
          card={card}
          plugins={
            <ConvoEditorCommandsPlugin
              actions={actions}
              commentIfContent={commentIfContent}
              onDone={handleDone}
            />
          }
          bottom={
            v2Active && meParticipating && <SnoozeUntilButton resBag={resBag} resNoti={resNoti} />
          }
        />
        <Box height="16px" />
      </CommentLikeContainer>
      {actions.map((action, idx) => (
        <ActionButton
          key={action.message}
          action={action}
          isWriting={!isEmpty}
          isLast={idx === actions.length - 1}
          project={card.deck?.project ?? null}
          resBag={resBag}
        />
      ))}
    </>
  );
};

type ActionButtonProps = {
  action: ConvoAction;
  isWriting?: boolean;
  isLast?: boolean;
  project: Project | null;
  resBag: ResolvableBag;
};

const ActionButton = (props: ActionButtonProps) => {
  const {action, isLast, isWriting, resBag} = props;
  const disabled = isWriting || action.disabled;

  if (
    (action.type === "approve-and-leave" || action.type === "opt-out") &&
    hasFeatureFlag(resBag.root.account, FEATURE_FLAGS.reactions)
  ) {
    return <ApproveButton {...props} />;
  }

  return (
    <>
      <Row className={hoverGroupContainer} alignSelf="start">
        <TooltipForChild
          tooltip={isWriting ? "Post your comment above before closing." : action.tooltip}
          targetIsDisabled={disabled}
          placement="left"
        >
          <DSIconButton
            icon={action.icon}
            size="sm"
            onClick={action.onClick ?? undefined}
            disabled={disabled}
            {...action.buttonProps}
          />
        </TooltipForChild>
        <div
          className={css({
            textType: "label14",
            color: "secondary",
            cursor: "pointer",
            pt: "4px",
            pl: "12px",
          })}
          onClick={(disabled ? null : action.onClick) ?? undefined}
        >
          {action.message}
        </div>
      </Row>
      {!isLast && (
        <Col height="24px" width="24px" className={styles.setAccentAsBorder}>
          <ConvoLine />
        </Col>
      )}
    </>
  );
};

const CloseButton = ({resBag}: {resBag: ResolvableBag}) => {
  const {card, status, onReopen, resolvable} = resBag;
  const cardId = card.cardId as CardId;
  const [doReopen] = useMutation("resolvables", "reopen");

  const handleReopenButtonClick = () => {
    return doReopen({id: resolvable.id, isClosed: false, cardId}).then(() =>
      onReopen(resolvable.id as ResolvableId)
    );
  };
  const ctx = resolvable.$meta.get("context", "comment");

  const getCannotReopenReason = () => {
    if (ctx === "comment") return null;
    if (status === "review") {
      return "Can't reopen this conversation while the card is in review already";
    }
    if (status === "block") {
      return "Can't reopen this conversation while the card is blocked";
    }
    if (card.isDoc) {
      return "Doc cards can't be in review or blocked";
    }
    if (isHeroCard(card)) {
      return "Hero cards can't be in review or blocked";
    }
    return null;
  };

  const cannotReopenReason = getCannotReopenReason();
  const disabled = Boolean(cannotReopenReason);

  return (
    <TooltipForChild tooltip={cannotReopenReason} targetIsDisabled={disabled}>
      <DSButton variant="secondary" disabled={disabled} onClick={handleReopenButtonClick}>
        Re-Open
      </DSButton>
    </TooltipForChild>
  );
};

const DismissNotificationsButton = ({resBag}: {resBag: ResolvableBag}) => {
  const v2Active = hasFeatureFlag(resBag.root.account, FEATURE_FLAGS.notificationV2);

  const [doDismiss] = useMutation(
    "notifications",
    v2Active ? "forceDismissResolvables" : "dismissResolvables"
  );

  const {resolvable, root} = resBag;

  const handleDismiss = () =>
    doDismiss({
      resolvableIds: [resolvable.id],
      userId: root.loggedInUser.id,
    });

  return (
    <DSButton variant="secondary" onClick={handleDismiss}>
      Dismiss Notifications
    </DSButton>
  );
};

const BottomActions = (props: {
  resBag: ResolvableBag;
  resNoti: ResolvableNotification | undefined;
}) => {
  const {resBag, resNoti} = props;
  const {canModify, resolvable} = resBag;
  const showCloseButton = canModify && resolvable.isClosed;
  const offerDismissButton = resNoti && !resNoti.isSnoozing;
  if (!showCloseButton && !offerDismissButton) return null;
  return (
    <Row sp="8px">
      {showCloseButton && <CloseButton {...props} />}
      {offerDismissButton && <DismissNotificationsButton {...props} />}
    </Row>
  );
};

const closeMessageByCtx = {
  comment: "closed this thread.",
  block: "unblocked this card.",
  review: "closed this review.",
};

type ConvoEntry =
  | {type: "entry"; item: ResolvableEntry; timestamp: number; key: string}
  | {type: "history"; item: ResolvableParticipantHistory; timestamp: number; key: string};

const useMergedEntriesAndOptouts = (resolvable: Resolvable): ConvoEntry[] => {
  const {participantHistories, entries} = resolvable;
  return useMemo(() => {
    const finalList: ConvoEntry[] = [];
    const mixedList: ConvoEntry[] = [
      ...participantHistories
        .filter((model) => model.$meta.get("version", 2) > 1)
        .map(
          (model) =>
            ({
              type: "history",
              item: model,
              timestamp: model.lastChangedAt.getTime() - 1,
              // - 1 to ensure that concurrent changes favour rpHistoryEntries
              // example: I re-add a user via `come back @riad`
              key: `h-${model.user.id}-${model.version}`,
            }) as const
        ),
      ...entries.map(
        (model) =>
          ({
            type: "entry",
            item: model,
            timestamp: model.$meta.get("createdAt", new Date()).getTime(),
            key: `e-${model.entryId}`,
          }) as const
      ),
    ];

    const rawOrderedEntries = orderBy(mixedList, [(e) => e.timestamp]);
    const cleanedUpOrderedEntries: ConvoEntry[] = [];
    const userStatus: Record<string, boolean> = {};
    for (const e of rawOrderedEntries) {
      if (e.type === "entry") {
        cleanedUpOrderedEntries.unshift(e);
      } else {
        // if not seen before, they were "in"
        const prevStatus = userStatus[e.item.user.id] ?? true;
        const areIn = !e.item.done;
        if (prevStatus !== areIn) {
          cleanedUpOrderedEntries.unshift(e);
        }
        userStatus[e.item.user.id] = areIn;
      }
    }

    // status per person takes all particupation changes between entries into account and ensures that only the latest entry
    // is shown (if it's not canccelling out with the inital state)
    let statusPerPerson: Record<string, {current: string; final: string; latest: ConvoEntry}> = {};
    for (const entry of cleanedUpOrderedEntries) {
      const {type, item} = entry;
      if (type === "entry") {
        for (const value of Object.values(statusPerPerson)) {
          if (value.current === value.final) finalList.unshift(value.latest);
        }
        finalList.unshift(entry);
        statusPerPerson = {};
      } else {
        const status = item.done ? "out" : "in";
        if (statusPerPerson[item.user.id]) {
          statusPerPerson[item.user.id].current = status;
        } else {
          statusPerPerson[item.user.id] = {
            current: status,
            final: status,
            latest: entry,
          };
        }
      }
    }
    return finalList;
  }, [participantHistories, entries]);
};

type OptInOutProps = {
  rpHistory: ResolvableParticipantHistory;
  resBag: ResolvableBag;
};
const OptInOut = (props: OptInOutProps) => {
  const {rpHistory, resBag} = props;
  const {user, done, status} = rpHistory;
  const ageProps = useAgeColorProps(rpHistory.lastChangedAt);
  return (
    <CommentLikeContainer resBag={resBag} user={user} dimUser={done} pt={0} lineType="entry">
      <Row sp="8px" wrap pt="4px" pb="8px">
        <Text type="label14" color="secondary">
          <Box as="span" color="primary">
            {user.name}
          </Box>
          {done ? (status === "approve" ? " approved and left" : " opted out") : " opted back in"}
          {done && rpHistory.reaction ? (
            <>
              {" "}
              with <ApproveReaction value={rpHistory.reaction} inline />
            </>
          ) : null}
        </Text>
        <Text type="label12light" {...ageProps} ml="auto">
          <FromNow date={rpHistory.lastChangedAt} />
        </Text>
      </Row>
    </CommentLikeContainer>
  );
};

type EntryProps = {
  entry: ConvoEntry;
  resBag: ResolvableBag;
  isTarget: boolean;
  isUnseen: boolean;
  isLast: boolean;
  header?: ReactNode;
  onSeen: (entryId: ResolvableEntryId) => void;
};
const Entry = ({entry, resBag, isTarget, isUnseen, isLast, header, onSeen}: EntryProps) => {
  const {item, type} = entry;
  if (type === "entry") {
    return (
      <Comment
        entry={item}
        resBag={resBag}
        isTarget={isTarget}
        isUnseen={isUnseen}
        isLast={isLast}
        header={header}
        onSeen={onSeen}
      />
    );
  } else {
    return <OptInOut resBag={resBag} rpHistory={item} />;
  }
};

const JustClosed = ({resBag}: {resBag: ResolvableBag}) => {
  const [isSetToDone, setToDone] = useState(false);
  const {onCardDone, onEditCardClick, card, canModify, root, resolvable} = resBag;

  const cardId = card.$meta.get("cardId", null) as CardId;

  const handleCardDoneClick = async () => {
    await performDoneAction({cardIds: [cardId], onCardDone});
    setToDone(true);
  };

  const ctx = resolvable.$meta.get("context", "comment");
  const canClose = ctx !== "review" || !card.deck || hasPermissionToGuardCard(root, card);

  const actions: ConvoAction[] = [];
  if (canModify) {
    actions.push({
      type: "post-close-update-card",
      message: "Update card content?",
      onClick: onEditCardClick,
      icon: <DSIconPencil />,
      tooltip: "Update card content?",
    });
  }
  if (resolvable.context === "review" && !isHeroCard(card) && canClose) {
    actions.push({
      type: "post-close-mark-done",
      message: isSetToDone ? "Card is set to done." : "Set card to done?",
      onClick: handleCardDoneClick,
      icon: <DSIconCheck />,
      tooltip: "Set card to done?",
      disabled: isSetToDone || card.status === "done",
      buttonProps: {theme: "success"},
    });
  }

  return (
    <>
      {actions.map((action, idx) => (
        <ActionButton
          key={action.message}
          action={action}
          isLast={idx === actions.length - 1}
          project={card.deck?.project ?? null}
          resBag={resBag}
        />
      ))}
    </>
  );
};

const getUnseenNotiIdx = (
  resNoti: ResolvableNotification | undefined,
  entries: ConvoEntry[],
  locationBasedLastSeenEntry: null | {id: null | ResolvableEntryId}
) => {
  const getLastSeenEntryId = () => {
    if (resNoti) return [resNoti.latestSeenEntry?.entryId ?? null, true] as const;
    if (locationBasedLastSeenEntry) return [locationBasedLastSeenEntry.id, false] as const;
    return null;
  };
  const entryInfo = getLastSeenEntryId();
  if (!entryInfo) return [null, false] as const;
  const [entryId, isReal] = entryInfo;
  if (entryId == null) return [0, isReal] as const;
  const idx = entries.findIndex((e) => e.type === "entry" && e.item.entryId === entryId);
  if (idx === -1) return [null, false] as const;
  return [idx + 1, isReal] as const;
};

const OpenContent = (props: {
  resBag: ResolvableBag;
  actions: ConvoAction[];
  isTarget: boolean;
  header: ReactNode;
  locationBasedLastSeenEntry: null | {id: null | ResolvableEntryId};
  resetSeen: () => void;
}) => {
  const {resBag, actions, isTarget, header, locationBasedLastSeenEntry, resetSeen} = props;
  const {resolvable, lastClosedResolvableId, notifications} = resBag;

  const ctx = resolvable.$meta.get("context", "comment");
  const userId = resBag.root.loggedInUser?.id as UserId;
  const resolvableId = resBag.resolvable.id as ResolvableId;

  const entries = useMergedEntriesAndOptouts(resolvable);
  const justClosed = lastClosedResolvableId === resolvableId;
  const lastEntry = entries.findLast((e) => e.type === "entry") ?? null;
  const firstEntry = entries.find((e) => e.type === "entry") ?? null;
  const targetEntry = isTarget && lastEntry;

  const resNoti: ResolvableNotification | undefined = notifications?.perResolvable[resolvableId];
  // isReal has been introduced as clicking on notification now dismisses them
  // so we use the location state to check if the notification also included unseen entries
  // the i.e. non-real info is based on location state and needs to be reset differently from
  // the the real one
  const [unseenIdx, isReal] = getUnseenNotiIdx(resNoti, entries, locationBasedLastSeenEntry);
  const ageProps = useAgeColorProps(resolvable.closedAt);

  const handleSeen = (entryId: ResolvableEntryId) => {
    api.mutate.notifications.seenResolvableEntries({
      userId,
      resolvableId,
      latestSeenEntryId: entryId,
    });
    if (!isReal) resetSeen();
  };

  return (
    <Col sp="24px">
      <Col>
        {entries.map((entry, idx) => (
          <Entry
            key={entry.key}
            entry={entry}
            resBag={resBag}
            isTarget={targetEntry === entry}
            isUnseen={unseenIdx !== null && idx >= unseenIdx}
            isLast={entry === lastEntry}
            header={entry === firstEntry ? header : undefined}
            onSeen={handleSeen}
          />
        ))}
        {resolvable.isClosed ? (
          <>
            <CommentLikeContainer
              resBag={resBag}
              user={resolvable.closedBy}
              dimUser
              lineType={justClosed ? "entry" : "none"}
              pt={0}
            >
              <Row sp="8px" wrap pt="4px" pb="8px">
                {resolvable.closedBy ? (
                  <Text type="label14" color="secondary">
                    <Box as="span" color="primary">
                      {resolvable.closedBy.name}
                    </Box>{" "}
                    {closeMessageByCtx[ctx]}
                  </Text>
                ) : (
                  <Text type="label14" color="secondary">
                    Thread closed
                  </Text>
                )}
                {resolvable.closedAt && (
                  <Text type="label12light" {...ageProps} ml="auto">
                    <FromNow date={resolvable.closedAt} />
                  </Text>
                )}
              </Row>
            </CommentLikeContainer>
            {justClosed && <JustClosed {...props} />}
          </>
        ) : (
          process.env.REACT_APP_MODE !== "open" && (
            <AddCommentForm {...props} actions={actions} resNoti={resNoti} />
          )
        )}
      </Col>
      <BottomActions {...props} resNoti={resNoti} />
    </Col>
  );
};

const ClosedContent = (props: {resBag: ResolvableBag; header: ReactNode}) => {
  const {
    resBag,
    resBag: {resolvable, setIsOpen},
    header,
  } = props;
  const firstEntry = resolvable.$meta.first("entries", {$order: "createdAt"})!;
  const entryCount = resolvable.$meta.count("entries");
  const remainingCount = entryCount - 1;
  return (
    <Col sp="8px">
      <Comment entry={firstEntry} resBag={resBag} isPreview header={header} onSeen={null} />
      {setIsOpen && (
        <Box pl="8px">
          <DSButton variant="tertiary" negatePadding onClick={() => setIsOpen(true)} size="sm">
            {remainingCount > 0 ? pluralize(remainingCount, "more comment") : "No more comments"}
          </DSButton>
        </Box>
      )}
    </Col>
  );
};

type _ConversationThreadProps = {
  resolvable: Resolvable;
  bag: ConvoBag;
};
const ConversationThread = ({resolvable, bag}: _ConversationThreadProps) => {
  const [isOpen, setIsOpen] = useState(!resolvable.isClosed);
  const [highlighted, setHighlighted] = useState(false);
  const [lastScrolledToResId, setLastScrolledToResId] = useState<null | ResolvableId>(null);
  const {shownActions, avatarAction} = isOpen
    ? getConversationActions({
        bag,
        resolvable,
      })
    : {shownActions: [], avatarAction: null};

  const {cardTargetResolvableId, cardLatestSeenEntryId} = (bag.location.state || {}) as {
    cardTargetResolvableId?: ResolvableId;
    cardLatestSeenEntryId?: null | ResolvableEntryId;
  };

  const isTarget = cardTargetResolvableId === resolvable.id;
  useEffect(() => {
    if (isTarget) setIsOpen(true);
  }, [isTarget]);

  const trigger = useDelayedTrigger();

  const resBag = createResolvableBag(
    resolvable,
    {
      ...bag,
      onScrollToTarget: () => {
        setHighlighted(true);
        trigger.fire(() => setHighlighted(false), 2000);
        bag.onScrollToTarget();
        setLastScrolledToResId(resolvable.id as ResolvableId);
      },
    },
    isOpen,
    setIsOpen
  );

  const header = <ConvoHeader avatarAction={avatarAction} resBag={resBag} />;

  const getLocationBasedSeenEntry = () => {
    if (isTarget || lastScrolledToResId === resolvable.id)
      return cardLatestSeenEntryId !== undefined ? {id: cardLatestSeenEntryId} : null;
    return null;
  };

  return (
    <ConvoContainer resBag={resBag} highlighted={highlighted}>
      {isOpen ? (
        <>
          <OpenContent
            resBag={resBag}
            actions={shownActions}
            isTarget={isTarget}
            locationBasedLastSeenEntry={getLocationBasedSeenEntry()}
            header={<Box height="32px" />}
            resetSeen={() => setLastScrolledToResId(null)}
          />
          <StickyConvoHeader avatarAction={avatarAction} resBag={resBag} />
        </>
      ) : (
        <ClosedContent resBag={resBag} header={header} />
      )}
    </ConvoContainer>
  );
};

export default ConversationThread;
