import deepEqual from "deep-equal";
import {dateStrToDay} from "../date-utils";
import {toBigInt} from "../utils";

const STATUS = {
  invalid: "invalid",
  loading: "loading",
};

export class Changes {
  changes = {};
  // 'type' can also be "touched"
  // 'touched' means that no data has been changed, but that data that has been uncertain (STATUS.INVALID)
  // is now certain again. this leads to calling _clearCache on loaded instances
  add(model, id, type = "updated") {
    const obj = this.changes[type] || (this.changes[type] = {});
    if (!obj[model]) {
      obj[model] = new Set([id]);
    } else {
      obj[model].add(id);
    }
  }
}

const updateExistingValuesAndDeleteStatus = (entry, newData) => {
  let hasChanged = false;
  const existingData = entry.value;
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const field in newData) {
    const newValue = newData[field];
    if (!(field in existingData) || !deepEqual(existingData[field], newValue, {strict: true})) {
      existingData[field] = newValue;
      hasChanged = true;
    }
    delete entry.status[field];
  }
  return hasChanged;
};

function collectFieldDeps(descriptions) {
  // fieldDeps = {Card: {content: {fields: ["title", "tags"], rels: []}}
  const deps = {};
  Object.keys(descriptions).forEach((modelName) => {
    deps[modelName] = {};
    const belongsToDescriptions = descriptions[modelName].belongsTo;
    Object.keys(belongsToDescriptions).forEach((relName) => {
      const belongsToDesc = belongsToDescriptions[relName];
      const {rels} = (deps[modelName][belongsToDesc.fk] = deps[modelName][belongsToDesc.fk] || {
        fields: [],
        rels: [],
      });
      const counterParts = descriptions[belongsToDesc.model].hasMany;
      Object.keys(counterParts)
        .filter((counterRelName) => {
          const hasManyDesc = counterParts[counterRelName];
          return hasManyDesc.model === modelName && hasManyDesc.fk === belongsToDesc.fk;
        })
        .forEach((counterRelName) => {
          rels.push({
            modelName: belongsToDesc.model,
            relName: counterRelName,
            fk: belongsToDesc.fk,
          });
        });
    });
    const fieldDescriptions = descriptions[modelName].fields;
    Object.keys(fieldDescriptions).forEach((fieldName) => {
      deps[modelName][fieldName] = {fields: [], rels: []};
    });
    Object.keys(fieldDescriptions).forEach((fieldName) => {
      fieldDescriptions[fieldName].deps.forEach((dep) =>
        deps[modelName][dep].fields.push(fieldName)
      );
    });
    Object.keys(belongsToDescriptions).forEach((relName) => {
      const belongsToDesc = belongsToDescriptions[relName];
      belongsToDesc.deps.forEach((fieldName) =>
        deps[modelName][fieldName].fields.push(belongsToDesc.fk, relName)
      );
    });
  });
  Object.keys(descriptions).forEach((modelName) => {
    const hasManyDescriptions = descriptions[modelName].hasMany;
    Object.keys(hasManyDescriptions).forEach((relName) => {
      const hasManyDesc = hasManyDescriptions[relName];
      hasManyDesc.deps.forEach((dep) =>
        deps[hasManyDesc.model][dep].rels.push({modelName, relName, fk: hasManyDesc.fk})
      );
    });
  });
  return deps;
}

const idsToCacheKey = (ids) => (ids.length === 1 ? `${ids[0]}` : JSON.stringify(ids));
const deprecatedFields = {};
const isDeprecatedField = ({modelName, field}) =>
  deprecatedFields[modelName] && deprecatedFields[modelName][field];

function collectHasManyModelDeps(descriptions) {
  // hasManyModelDeps = {attachment: [{model: "card", relName: "attachments", fk: "cardId"}]}
  const deps = {};
  Object.keys(descriptions).forEach((modelName) => {
    deps[modelName] = [];
  });
  Object.keys(descriptions).forEach((modelName) => {
    const hasManyDescriptions = descriptions[modelName].hasMany;
    Object.keys(hasManyDescriptions).forEach((relName) => {
      const {model, fk, via} = hasManyDescriptions[relName];
      if (model === "$deprecated") {
        (deprecatedFields[model] = deprecatedFields[model] || {})[relName] = true;
        return;
      }
      deps[model].push({model: modelName, relName, fk, via: (via && via.table) || via});
    });
  });
  return deps;
}

function collectBelongsToModelDeps(descriptions) {
  // belongsToModelDeps = {milestone: [{model: "card", relName: "milestone", fk: "cardId"}]}
  const deps = {};
  Object.keys(descriptions).forEach((modelName) => {
    deps[modelName] = [];
  });
  Object.keys(descriptions).forEach((modelName) => {
    const belongsToDescriptions = descriptions[modelName].belongsTo;
    Object.keys(belongsToDescriptions).forEach((relName) => {
      const {model, fk} = belongsToDescriptions[relName];
      deps[model].push({model: modelName, relName, fk});
    });
  });
  return deps;
}

function collectFksToFields(descriptions) {
  const fksToFields = {};
  Object.keys(descriptions).forEach((modelName) => {
    fksToFields[modelName] = {};
    const belongsDesc = descriptions[modelName].belongsTo;
    Object.entries(belongsDesc).forEach(([relName, {fk}]) => {
      fksToFields[modelName][fk] = relName;
    });
  });
  return fksToFields;
}

export const cardAccountSeqToCardId = new Map();

export default function createCache(descriptions, collector, onChange, registerOnChangeListener) {
  const fieldDeps = collectFieldDeps(descriptions);
  const hasManyModelDeps = collectHasManyModelDeps(descriptions);
  const belongsToModelDeps = collectBelongsToModelDeps(descriptions);
  const fksToFields = collectFksToFields(descriptions);

  const onEntryLoadedHooks = {
    card: (entry) => {
      if (entry.accountSeq) {
        cardAccountSeqToCardId.set(entry.accountSeq, entry.cardId);
      }
    },
  };

  const modelCache = {};
  const optModelCache = {}; // optimistic cache
  // optModelCache = {
  //   card: {
  //     1: {
  //       priority: [{value: 3, mutationId: 2}, {value: 2, mutationId: 4}]
  //     }
  //   }
  // };
  const fieldsAffectingRelations = {};
  // {card: {title: {account: Set(["cards({title: 'something'}"])}}

  const relsAffectingRelations = {};
  /*
    sample rel:
    account.$meta.find("cards", {handCards: {userId: 3}})

    result in relsAffectingRelations
    {handCards: {account: Set(["cards(...)"])}}

    note that handCard's cardId will be mapped in `fieldsAffectingRelations`

  */

  const invalidateField = ({cacheEntry, fieldName, modelName}) => {
    cacheEntry.status[fieldName] = STATUS.invalid;
  };

  const popFromOptModelCache = (mutationId, cb) => {
    Object.keys(optModelCache).forEach((modelName) => {
      const modelIds = Object.keys(optModelCache[modelName]);
      let deletedModelIds = 0;
      modelIds.forEach((id) => {
        const cacheEntry = optModelCache[modelName][id];
        const fieldNames = Object.keys(cacheEntry);
        let deletedNames = 0;
        fieldNames.forEach((field) => {
          const deleteIdx = [];
          const fields = optModelCache[modelName][id][field];
          fields.forEach(({value, mutationId: entryMutationId, type, onResolve}, i) => {
            if (entryMutationId === mutationId) {
              if (cb) cb({modelName, id, field, value, type, onResolve});
              deleteIdx.push(i);
            }
          });
          if (deleteIdx.length > 0) {
            let offset = -1;
            deleteIdx.forEach((i) => fields.splice(i - (offset += 1), 1));
            if (fields.length === 0) {
              delete optModelCache[modelName][id][field];
              deletedNames += 1;
            }
          }
        });
        if (deletedNames === fieldNames.length) {
          delete optModelCache[modelName][id];
          deletedModelIds += 1;
        }
      });
      if (deletedModelIds === modelIds.length) {
        delete optModelCache[modelName];
      }
    });
  };

  const findAffectedRelations = (mutationDesc, data, cb) => {
    const {model: modelName, via} = mutationDesc;
    const modelDesc = descriptions[modelName];
    const maybeIds = modelDesc.idPropAsArray.map((prop) => data[prop]);
    let idKey = maybeIds.every((val) => val !== undefined) ? idsToCacheKey(maybeIds) : null;
    if (!idKey && data.id && modelDesc.idPropAsArray.length === 1) {
      idKey = data.id;
    }
    const cacheEntry = (idKey && modelCache[modelName] && modelCache[modelName][idKey]) || {
      value: {},
    };
    // hasManyModelDeps = {attachment: [{model: "card", relName: "attachments", fk: "cardId"}]}
    hasManyModelDeps[modelName].forEach(({model: parentModelName, relName, fk, via: parentVia}) => {
      if (via && via !== parentVia) return;
      const parentCacheEntries = [];
      if (parentModelName === "_root") {
        parentCacheEntries.push({parentCacheEntry: modelCache[parentModelName], parentId: null});
      } else {
        if (data[fk] === null) return; // explicitly has no parent
        const parentId = data[fk] || cacheEntry.value[fk] || data.id;
        const ids = parentId ? [parentId] : data.ids;
        if (ids && ids.length) {
          const parentModelCache = modelCache[parentModelName];
          if (parentModelCache)
            ids.forEach(
              (id) =>
                parentModelCache[id] &&
                parentCacheEntries.push({parentCacheEntry: parentModelCache[id], parentId: id})
            );
        }
        if (!parentCacheEntries.length) {
          Object.keys(modelCache[parentModelName] || {}).forEach((k) => {
            if (modelCache[parentModelName][k])
              parentCacheEntries.push({
                parentCacheEntry: modelCache[parentModelName][k],
                parentId: k,
              });
          });
          if (process.env.NODE_ENV !== "production") {
            // eslint-disable-next-line no-console
            console.info(
              `could not find fk "${fk}" within modified "${modelName}" instance or within passed data, therefore invalidating ${parentCacheEntries.length} ${parentModelName} for ${relName}`
            );
          }
        }
      }

      const fieldVariantsRegex = new RegExp(`^(count:|exists:)?${relName}($|\\()`);
      parentCacheEntries.forEach(({parentCacheEntry, parentId}) => {
        Object.keys(parentCacheEntry.value).forEach((relField) => {
          // HINT: could optimize delete by looking if data.id is part of the relation
          if (fieldVariantsRegex.test(relField)) {
            cb(parentCacheEntry, relField, parentModelName, parentId);
          }
        });
      });
    });
  };

  const getModelFromCache = (modelName, id) => {
    const mCache = modelCache[modelName];
    if (!mCache) return undefined;
    const eCache = mCache[id];
    return eCache && eCache.value;
  };

  const cache = {
    modelCache,
    add(data) {
      const changes = new Changes();
      Object.keys(data).forEach((modelName) => {
        if (!(modelName in descriptions)) throw new Error(`Don't know model "${modelName}"`);
        if (modelName === "_root") {
          const existing = modelCache[modelName];
          const entry = data[modelName];
          if (existing) {
            const hasUpdated = updateExistingValuesAndDeleteStatus(existing, entry);
            if (hasUpdated) {
              changes.add(modelName, null);
            } else {
              changes.add(modelName, null, "touched");
            }
          } else {
            modelCache[modelName] = {
              value: entry,
              status: {},
            };
          }
        } else {
          modelCache[modelName] = modelCache[modelName] || {};
          const hook = onEntryLoadedHooks[modelName];
          const entries = data[modelName];
          const desc = descriptions[modelName];
          Object.keys(entries).forEach((entryId) => {
            const entry = entries[entryId];
            if (entry === null) {
              if (process.env.NODE_ENV !== "production") {
                console.warn(
                  `trying to add ${modelName}(${entryId}) to cache, but it's deleted/inaccessible`
                );
              }
              changes.add(modelName, entryId);
              modelCache[modelName][entryId] = null;
            } else {
              Object.keys(entry).forEach((fieldName) => {
                const fieldType = desc.fields[fieldName]?.type;
                if (fieldType === "date") {
                  entry[fieldName] = entry[fieldName] === null ? null : new Date(entry[fieldName]);
                } else if (fieldType === "day") {
                  entry[fieldName] = entry[fieldName] && dateStrToDay(entry[fieldName]);
                } else if (fieldType === "bigint") {
                  entry[fieldName] = toBigInt(entry[fieldName]);
                }
              });
              if (hook) hook(entry);
              const existing = modelCache[modelName][entryId];
              if (existing) {
                const hasUpdated = updateExistingValuesAndDeleteStatus(existing, entry);
                if (hasUpdated) {
                  changes.add(modelName, entryId);
                } else {
                  changes.add(modelName, entryId, "touched");
                }
              } else {
                changes.add(modelName, entryId);
                modelCache[modelName][entryId] = {
                  value: entry,
                  status: {},
                };
              }
            }
          });
        }
      });
      onChange(changes.changes);
    },
    // hint: could also be just inaccessible.
    isDeleted(modelName, id) {
      return !!modelCache[modelName] && modelCache[modelName][id] === null;
    },
    // status: "loaded" | "loading" | "refreshing" | "optimistic" | "notLoaded" | "invalid" | "deleted"
    getAndTellIfLoaded({modelName, id, field, collectIfMissing}) {
      const cachedVals = modelCache[modelName];
      let cachedEntry;
      if (modelName === "_root") {
        cachedEntry = cachedVals;
      } else {
        cachedEntry = cachedVals && cachedVals[id];
      }
      const optCachedVals = optModelCache[modelName];
      if (optCachedVals) {
        const optCachedEntry = optCachedVals[id];
        if (optCachedEntry) {
          const fieldVals = optCachedEntry[field];
          if (fieldVals && fieldVals.length) {
            // if val === undefined, api.js told us: we don't know yet. use an existing value, or the default instead
            const val = fieldVals[fieldVals.length - 1].value;
            const useDefaultVal =
              val !== undefined && (!cachedEntry || !(field in cachedEntry.value));
            return {
              val:
                val !== undefined
                  ? val
                  : cachedEntry && field in cachedEntry.value
                    ? cachedEntry.value[field]
                    : undefined,
              isLoaded: false,
              status: "optimistic",
              useDefaultVal,
            };
          }
        }
      }

      if (cachedEntry === null) {
        console.warn(
          `tried to get "${field}" on "${modelName}" with id "${id}" which is a deleted/inaccessible resource. Prevent this via checking for \`$meta.isDeleted()\``
        );
        return {val: null, isLoaded: true, status: "deleted", useDefaultVal: false};
      }
      if (isDeprecatedField({modelName, field})) {
        return {val: null, isLoaded: true, status: "deleted", useDefaultVal: false};
      }
      if (cachedEntry === undefined) {
        if (collectIfMissing) {
          cachedEntry = {
            value: {},
            status: {},
          };
          if (modelName === "_root") {
            modelCache[modelName] = cachedEntry;
          } else {
            if (!modelCache[modelName]) modelCache[modelName] = {};
            modelCache[modelName][id] = cachedEntry;
          }
          collector.collect([{modelName, id}], field, collectIfMissing);
          cachedEntry.status[field] = STATUS.loading;
        }
        return {
          val: undefined,
          isLoaded: false,
          status: collectIfMissing ? "loading" : "notLoaded",
          useDefaultVal: true,
        };
      } else {
        const isFieldPresent = field in cachedEntry.value;
        const status = cachedEntry.status[field];
        const needsToCollect = !isFieldPresent || status;

        if (
          collectIfMissing &&
          needsToCollect &&
          status !== STATUS.loading &&
          status !== "refreshing"
        ) {
          const desc = descriptions[modelName];
          if (desc && desc.isFunction) {
            // there's no way to update rows returned from a function. So let's just ignore those
            delete cachedEntry.status[field];
          } else {
            collector.collect([{modelName, id}], field, collectIfMissing, status);
          }
          cachedEntry.status[field] = status === "invalid" ? "refreshing" : STATUS.loading;
        }
        return isFieldPresent
          ? {
              val: cachedEntry.value[field],
              isLoaded: !needsToCollect,
              status: cachedEntry.status[field] || "loaded",
              useDefaultVal: false,
            }
          : {
              val: undefined,
              isLoaded: !needsToCollect,
              status: cachedEntry.status[field] || "notLoaded",
              useDefaultVal: true,
            };
      }
    },
    /**
     * @param {object} opts
     * @param {function=} opts.onDone
     * @param {boolean=} opts.delayReload
     */
    clear({onDone, delayReload}) {
      collector.clear();
      if (delayReload) {
        collector.delayCollecting(50, () => onChange("all"));
      }
      Object.keys(modelCache).forEach((key) => delete modelCache[key]);
      Object.keys(optModelCache).forEach((key) => delete optModelCache[key]);
      if (onDone) {
        const unsub = registerOnChangeListener(() => {
          onDone();
          unsub();
        });
      }
      onChange("all");
    },
    invalidateAll() {
      const invalidateAllFields = ({value, status}) => {
        for (const field in value) status[field] = STATUS.invalid;
      };
      for (const key in modelCache) {
        const val = modelCache[key];
        if (key === "_root") {
          invalidateAllFields(val);
        } else {
          for (const id in val) {
            const model = val[id];
            if (model) invalidateAllFields(model);
          }
        }
      }
      onChange("all");
    },
    addOptimisticAction(
      mutationId,
      mutationDesc,
      rawData,
      isImplicit = false,
      changes = new Changes()
    ) {
      const {model: modelName, convertDataForOptimistic} = mutationDesc;
      const modelDesc = descriptions[modelName];
      const data = convertDataForOptimistic ? convertDataForOptimistic(rawData) : rawData;
      const getIdAndRest = () => {
        const idParts = [];
        const hasAllParts = modelDesc.idPropAsArray.every((idProp) => {
          if (data[idProp]) {
            idParts.push(data[idProp]);
            return true;
          }
          return false;
        });
        return hasAllParts
          ? {
              ...Object.fromEntries(
                Object.entries(data).filter(([k]) => !modelDesc.idPropAsArray.includes(k))
              ),
              id: idsToCacheKey(idParts),
            }
          : data;
      };

      const {id, ids, ...changedFields} = modelDesc ? getIdAndRest() : {};
      if (mutationDesc.type === "update") {
        const changeFieldNames = Object.keys(changedFields);
        if ((id || ids) && changeFieldNames.length) {
          const models = (optModelCache[modelName] = optModelCache[modelName] || {});
          (id ? [id] : ids).forEach((innerId) => {
            const entry = (models[innerId] = models[innerId] || {});
            changes.add(modelName, innerId);
            changeFieldNames.forEach((field) => {
              const fieldName = fksToFields[modelName][field] || field;
              (entry[fieldName] = entry[fieldName] || []).push({
                value: changedFields[field],
                mutationId,
                type: "update",
                onResolve: mutationDesc.onResolve,
              });
            });
          });
        }
      } else if (mutationDesc.type === "create") {
        const fields = Object.keys(data).filter(
          (field) =>
            field !== "id" &&
            (fksToFields[modelName][field] || modelDesc.fields[field] || modelDesc.belongsTo[field])
        );
        if (fields.length === 0) return;
        const models = (optModelCache[modelName] = optModelCache[modelName] || {});
        const optId = id || `$opt$${mutationId}`;
        const entry = (models[optId] = models[optId] || {});
        fields.forEach((field) => {
          const fieldName = fksToFields[modelName][field] || field;
          (entry[fieldName] = entry[fieldName] || []).push({
            value: data[field],
            mutationId,
            type: "create",
            onResolve: mutationDesc.onResolve,
          });
        });
      } else if (mutationDesc.type === "delete") {
        if (id) {
          findAffectedRelations(
            mutationDesc,
            data,
            (parentCacheEntry, relName, parentModelName) => {
              const parentDesc = descriptions[parentModelName];
              const parentVal = parentCacheEntry.value[relName];
              if (Array.isArray(parentVal)) {
                const indexOfId = parentVal.indexOf(id);
                if (indexOfId >= -1) {
                  const parentId = parentCacheEntry.value[parentDesc.idProp];
                  const models = (optModelCache[parentModelName] =
                    optModelCache[parentModelName] || {});
                  const entry = (models[parentId] = models[parentId] || {});
                  changes.add(parentModelName, parentId);
                  const fieldVals = (entry[relName] = entry[relName] || []);
                  const latestValue = fieldVals.length
                    ? fieldVals[fieldVals.length - 1].value
                    : parentVal;
                  fieldVals.push({
                    value: latestValue.filter((relId) => relId !== id),
                    mutationId,
                    type: "deleteFromCollection",
                    onResolve: mutationDesc.onResolve,
                  });
                }
              } else if (parentVal && parentVal === id) {
                const parentId = parentCacheEntry.value[parentDesc.idProp];
                const models = (optModelCache[parentModelName] =
                  optModelCache[parentModelName] || {});
                const entry = (models[parentId] = models[parentId] || {});
                changes.add(parentModelName, parentId);
                (entry[relName] = entry[relName] || []).push({
                  value: null,
                  mutationId,
                  type: "deleteFromCollection",
                  onResolve: mutationDesc.onResolve,
                });
              }
            }
          );
        }
      } else if (mutationDesc.type === "custom") {
        mutationDesc.fn({
          modelCache,
          optModelCache,
          data,
          mutationId,
          addOptimisticAction: cache.addOptimisticAction,
          changes,
          getAndTellIfLoaded: cache.getAndTellIfLoaded,
        });
      } else if (mutationDesc.type === "void") {
        // don't do anything, this is used for implicit updates only
      }
      if (mutationDesc.implicit) {
        const list = mutationDesc.implicit(data, {
          _isOptimistic: true,
          _getModelFromCache: getModelFromCache,
        });
        list.forEach(({desc: implDesc, data: implData}) =>
          cache.addOptimisticAction(mutationId, implDesc, implData, true, changes)
        );
      }
      if (!isImplicit) onChange(changes.changes);
    },
    failedOptimisticAction(mutationId) {
      popFromOptModelCache(mutationId);
      onChange("all");
    },
    resolveOptimisticAction(mutationId, mutationDesc, data, rawRetVal, changes) {
      popFromOptModelCache(
        mutationId,
        ({modelName, id, field, value: rawValue, type, onResolve}) => {
          const {value, retVal} = (onResolve &&
            onResolve({id, field, retVal: rawRetVal, value: rawValue})) || {
            value: rawValue,
            retVal: rawRetVal,
          };
          if (type === "update" || type === "deleteFromCollection") {
            const cacheEntry = (modelCache[modelName] = modelCache[modelName] || {})[id];
            changes.add(modelName, id);
            if (!cacheEntry) return;
            if (value === undefined || (typeof value === "string" && value.startsWith("$opt$"))) {
              cacheEntry.status[field] = STATUS.invalid;
            } else {
              cacheEntry.value[field] = value;
            }
          } else if (type === "create") {
            if (!retVal || !retVal.id) return;
            const entries = (modelCache[modelName] = modelCache[modelName] || {});
            const cacheEntry = (entries[retVal.id] = entries[retVal.id] || {
              value: {},
              status: {},
            });
            cacheEntry.value[field] = value;
            cacheEntry.status[field] = STATUS.invalid;
          }
        }
      );
      return changes;
    },
    invalidate(desc, data, retVal, isImplicit = false, changes = new Changes()) {
      if (desc.type === "update") {
        /* eslint-disable prefer-const */
        let {ids, ...changedFields} = data;
        /* eslint-enable prefer-const */
        const {model: modelName} = desc;
        const modelDesc = descriptions[modelName];
        const maybeIds = modelDesc.idPropAsArray.map((prop) => {
          const val = changedFields[prop];
          delete changedFields[prop];
          return val;
        });
        let entryId = maybeIds.every((val) => val !== undefined) ? idsToCacheKey(maybeIds) : null;
        if (!entryId && changedFields.id && modelDesc.idPropAsArray.length === 1) {
          entryId = changedFields.id;
          delete changedFields.id;
        }

        if (process.env.NODE_ENV !== "production" && !entryId && !ids) {
          // eslint-disable-next-line no-console
          console.info(
            `updating all ${
              Object.keys(modelCache[modelName] || {}).length
            } instances of ${modelName} since no id was given`
          );
        }
        ids = entryId ? [entryId] : ids || Object.keys(modelCache[modelName] || {});
        const affectedFields = fieldsAffectingRelations[modelName];
        ids.forEach((id) => {
          let cacheEntry = (modelCache[modelName] || {})[id];
          if (!cacheEntry) {
            // we're using a fake entry here, to evaluate whether stuff like `deckId` affects already loaded models
            cacheEntry = {value: data, status: {}};
          } else {
            changes.add(modelName, id);
          }
          Object.keys(changedFields).forEach((field) => {
            const fieldName = fksToFields[modelName][field] || field;
            invalidateField({cacheEntry, fieldName, modelName});

            const fieldDep = fieldDeps[modelName][field];
            if (fieldDep) {
              fieldDep.fields.forEach((dep) => {
                invalidateField({cacheEntry, fieldName: dep, modelName});
              });
              fieldDep.rels.forEach(({modelName: relModelName, relName, fk}) => {
                const relCacheEntry = modelCache[relModelName];
                if (!relCacheEntry) return;

                // if we know who owned the element before, we now can tell which relation to change
                let relIds = Object.keys(relCacheEntry).filter(
                  (relId) => relCacheEntry[relId] !== null
                );
                let filterIds = null;
                let hasFkInValue = fk in cacheEntry.value;
                let sourceFkVal;
                if (!hasFkInValue) {
                  // maybe `deckId` is not present as a field, but the belongsTo-value `deck`!?
                  const alternativeFk = Object.keys(descriptions[modelName].belongsTo).find(
                    (belongsToRelName) =>
                      descriptions[modelName].belongsTo[belongsToRelName].fk === fk
                  );
                  hasFkInValue = alternativeFk in cacheEntry.value;
                  if (hasFkInValue) sourceFkVal = cacheEntry.value[alternativeFk];
                } else {
                  sourceFkVal = cacheEntry.value[fk];
                }
                if (hasFkInValue) {
                  filterIds = [];
                  if (sourceFkVal) filterIds.push(sourceFkVal);
                  if (data[fk] && data[fk] !== sourceFkVal) filterIds.push(data[fk]);
                  relIds = relIds.filter((relId) =>
                    filterIds.includes(
                      relCacheEntry[relId].value[descriptions[relModelName].idProp]
                    )
                  );
                }

                const fieldVariantsRegex = new RegExp(`^(count:|exists:)?${relName}($|\\()`);
                relIds.forEach((relId) => {
                  const entry = relCacheEntry[relId];
                  Object.keys(entry.value).forEach((relField) => {
                    if (fieldVariantsRegex.test(relField)) {
                      entry.status[relField] = STATUS.invalid;
                      changes.add(relModelName, relId);
                    }
                  });
                });
              });
            }

            const affectedRels = affectedFields && affectedFields[field];
            if (affectedRels) {
              Object.entries(affectedRels).forEach(([parentModel, relNames]) => {
                Object.entries(modelCache[parentModel] || {}).forEach(([parentId, entry]) => {
                  if (!entry) return;
                  relNames.forEach((relName) => {
                    if (relName in entry.value || relName in entry.status) {
                      entry.status[relName] = STATUS.invalid;
                      // it's a change because $isLoaded now returns a different value and the model's cache isn't valid anymore
                      changes.add(parentModel, parentId);
                    }
                  });
                });
              });
            }

            const val = changedFields[field];
            if (val !== null && val !== undefined) {
              // change it last so that e.g. previous deckId can be accessed by rel-invalidations above
              cacheEntry.value[fieldName] = val;
            }
          });
        });
      } else if (desc.type === "create" || desc.type === "delete") {
        if (desc.type === "delete") {
          const modelDesc = descriptions[desc.model];
          const maybeIds = modelDesc.idPropAsArray.map((prop) => data[prop]);
          let idKey = maybeIds.every((val) => val !== undefined) ? idsToCacheKey(maybeIds) : null;
          if (!idKey && data.id && modelDesc.idPropAsArray.length === 1) idKey = data.id;
          if (!idKey) {
            console.warn(
              `deleting a resource without passing '${modelDesc.idPropAsArray.join(", ")}'`,
              data,
              desc
            );
          } else {
            const cacheEntry = (modelCache[desc.model] || {})[idKey];
            if (cacheEntry) {
              changes.add(desc.model, idKey);
              Object.keys(cacheEntry.value).forEach((key) => {
                cacheEntry.status[key] = STATUS.invalid;
              });
            }
            belongsToModelDeps[desc.model].forEach(({model: parentModelName, relName, fk}) => {
              Object.keys(modelCache[parentModelName] || {}).forEach((id) => {
                const parentCacheEntry = modelCache[parentModelName][id];
                if (!parentCacheEntry) return;
                if (parentCacheEntry.value[relName] === idKey) {
                  parentCacheEntry.status[relName] = STATUS.invalid;
                  changes.add(parentModelName, id);
                }
                if (parentCacheEntry.value[fk] === idKey) {
                  parentCacheEntry.status[fk] = STATUS.invalid;
                  changes.add(parentModelName, id);
                }
              });
            });
          }
        }
        findAffectedRelations(desc, data, (parentCacheEntry, relName, parentModelName, relId) => {
          changes.add(parentModelName, relId);
          parentCacheEntry.status[relName] = STATUS.invalid;
        });

        const affectedRels = relsAffectingRelations[desc.model];
        if (affectedRels) {
          Object.entries(affectedRels).forEach(([parentModel, relNames]) => {
            Object.entries(modelCache[parentModel] || {}).forEach(([parentId, entry]) => {
              if (!entry) return;
              relNames.forEach((relName) => {
                if (relName in entry.value || relName in entry.status) {
                  entry.status[relName] = STATUS.invalid;
                  // it's a change because $isLoaded now returns a different value and the model's cache isn't valid anymore
                  changes.add(parentModel, parentId);
                }
              });
            });
          });
        }
      } else if (desc.type === "custom") {
        desc.fn({modelCache, optModelCache, data});
      } else if (desc.type === "void") {
        // do nothing. This type exists just for implicit updates
      } else {
        throw new Error(`don't know action type "${desc.type}"!`);
      }
      if (desc.implicit) {
        desc
          .implicit(data, {...retVal, _getModelFromCache: getModelFromCache})
          .forEach(({desc: implDesc, data: implData}) => {
            cache.invalidate(implDesc, implData, retVal, true, changes);
          });
      }
      if (!isImplicit) onChange(changes.changes);
    },

    addConstraintBasedRelationField({parentModel, relName, targetModel, field}) {
      let byModel = fieldsAffectingRelations[targetModel];
      if (!byModel) byModel = fieldsAffectingRelations[targetModel] = {};
      let byField = byModel[field];
      if (!byField) byField = byModel[field] = {};
      let parentSet = byField[parentModel];
      if (!parentSet) parentSet = byField[parentModel] = new Set();
      parentSet.add(relName);
    },

    addConstraintBasedRelationRelation({parentModel, relName, targetModel, fk}) {
      /*
      sample rel:
      account.$meta.find("cards", {handCards: {userId: 3}})

      targetModelDesc: "card"
      parentModel: "account" + relName
      targetModel: "handCard"
      fk: cardId
      */

      // if a handCard's cardId is changed, invalidate the relation
      cache.addConstraintBasedRelationField({parentModel, relName, targetModel, field: fk});

      let byModel = relsAffectingRelations[targetModel];
      if (!byModel) byModel = relsAffectingRelations[targetModel] = {};
      let parentSet = byModel[parentModel];
      if (!parentSet) parentSet = byModel[parentModel] = new Set();
      parentSet.add(relName);
    },
  };
  return cache;
}
