import memoizeOne from "memoize-one";
import {Deck} from "../../cdx-models/Deck";

const emptySet = new Set();

const getSubMap = memoizeOne((subs: {deck: any; $meta: any}[]) => {
  if (subs.length && !subs[0].$meta.isLoaded) return emptySet;
  return new Set(subs.map((s) => s.deck?.id));
});

export const isUserSubscribedToDeck = ({
  root,
  deckId,
  userId,
}: {
  root: any;
  deckId: string;
  userId: string;
}) => {
  if (!userId || !deckId) return false;
  const subs = root.account.$meta.find("deckSubscriptions", {userId});
  const map = getSubMap(subs);
  return map.has(deckId);
};

export const getDeckCardType = (deck: Deck) => {
  const withHero = deck.allowedCardTypes.includes("hero");
  const withTask = deck.allowedCardTypes.includes("task");
  if (withHero && !withTask) return "hero";
  if (!withHero && !withTask) return "doc";
  return "mixed";
};
