export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const weekdayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const mutateNow = new Date();
export const toUTCHourToLocalHour = (hour) => {
  mutateNow.setUTCHours(hour);
  return mutateNow.getHours();
};

const workdayIdxs = [0, 1, 2, 3, 4, 5, 6];
const workdayKeys = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

/**
 * @param {
 *  account: import("../cdx-models/Account").Account,
 *  now?: Date,
 *  targetDay: Date,
 * } opts
 * @returns string
 */
export const prettyWorkdaysForAccount = ({account, now, targetDay}) =>
  prettyWorkdays({workdays: account.workdays, now, targetDay});

export const countWordaysForWithWorkdays = ({workdays, todayMidnight, targetDay}) =>
  countWordaysAndRealDays({workdays, todayMidnight, targetDay}).workdays;

/**
 * @param {
 *  account: import("../cdx-models/Account").Account,
 *  todayMidnight?: Date,
 *  targetDay: Date,
 * } opts
 * @returns string
 */
export const countWorkdaysForAccount = ({account, todayMidnight, targetDay}) =>
  countWordaysAndRealDays({workdays: account.workdays, todayMidnight, targetDay}).workdays;

export const countWorkdaysAndRealDaysForAccount = ({account, todayMidnight, targetDay}) =>
  countWordaysAndRealDays({workdays: account.workdays, todayMidnight, targetDay});

export const startOfDay = (date = new Date()) => {
  const next = new Date(date);
  next.setHours(0, 0, 0, 0);
  return next;
};

/**
 * @param {Date} date
 * @returns {number} monthKey
 * */
export const dateToMonthKey = (date) => monthYearToKey(date.getMonth(), date.getFullYear());

let _monthKeyCache = [];
/**
 * @param {number} monthKey
 * @returns {Date}
 * */
export const monthKeyToDate = (monthKey) => {
  let exist = _monthKeyCache[monthKey];
  if (exist) {
    return exist;
  } else {
    const month = monthKey % 12;
    const year = (monthKey - month) / 12;
    const date = new Date(year + 2010, month, 1);
    _monthKeyCache[monthKey] = date;
    return date;
  }
};

export const monthYearToKey = (month, year) => (year - 2010) * 12 + month;
export const startOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

let _monthLengthCache = {};
export const getMonthLength = (date) => {
  const month = date.getMonth();
  const year = date.getFullYear();
  const key = monthYearToKey(month, year);
  const exist = _monthLengthCache[key];
  if (exist) return exist;
  const lastDay = new Date(year, month + 1, 0);
  return (_monthLengthCache[key] = lastDay.getDate());
};

export const startOfHour = (date = new Date()) => {
  const next = new Date(date);
  next.setHours(date.getHours(), 0, 0, 0);
  return next;
};

export const startOfUTCDay = (date = new Date()) => {
  const next = new Date(date);
  next.setUTCHours(0, 0, 0, 0);
  return next;
};

export const prettyDayDiff = (d) => {
  const today = startOfDay(new Date());
  const dDay = startOfDay(d);
  const diff = diffDays(today, dDay);
  const label = (() => {
    switch (diff) {
      case 0:
        return "today";
      case 1:
        return "yesterday";
      default:
        if (diff <= 31) {
          return `${diff} days ago`;
        } else {
          return "over a month ago";
        }
    }
  })();
  return {label, dayDiff: diff};
};

const prettyWorkdays = ({workdays, now = new Date(), targetDay}) => {
  const todayMidnight = startOfDay(now);
  var diff = Math.round((targetDay - todayMidnight) / (24 * 3600 * 1000));
  switch (diff) {
    case 0:
      return "today";
    case 1:
      return "tomorrow";
    case -1:
      return "yesterday";
    default: {
      const days = rawCountWorkdays(workdays, todayMidnight, diff);
      if (diff < 0) {
        return `${-days} workday${-days === 1 ? "" : "s"} ago`;
      } else {
        return `in ${days} workday${days === 1 ? "" : "s"}`;
      }
    }
  }
};

export const prettyShortWorkdays = ({workdays, now = new Date(), targetDay}) => {
  const todayMidnight = startOfDay(now);
  var diff = Math.round((targetDay - todayMidnight) / (24 * 3600 * 1000));
  const days = rawCountWorkdays(workdays, todayMidnight, diff);
  if (diff === 0) {
    return "today";
  } else if (Math.abs(days) <= 10) {
    return `${days}d`;
  } else {
    return superShortDate(targetDay);
  }
};

const countWordaysAndRealDays = ({workdays, todayMidnight: raw, targetDay}) => {
  let todayMidnight = raw;
  if (!todayMidnight) {
    todayMidnight = setToMidnight(new Date());
  }
  var diff = Math.round((targetDay - todayMidnight) / (24 * 3600 * 1000));
  return {workdays: rawCountWorkdays(workdays, todayMidnight, diff), realDays: diff};
};

const rawCountWorkdays = (workdays, todayMidnight, diff) => {
  const todayWeekday = todayMidnight.getDay();
  const weekDays = workdayIdxs.filter((idx) => workdays[workdayKeys[idx]]);
  return weekDays.reduce(
    (carry, w) => carry + Math.floor((diff + ((todayWeekday + 6 - w) % 7)) / 7),
    0
  );
};

export const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const isYesterday = (someDate) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return (
    someDate.getDate() === yesterday.getDate() &&
    someDate.getMonth() === yesterday.getMonth() &&
    someDate.getFullYear() === yesterday.getFullYear()
  );
};

export const setToMidnight = (date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const pad2 = (num) => (num < 10 ? `0${num}` : `${num}`);

export const beginningOfDateStr = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d.toISOString();
};

export const endOfDateStr = (date) => {
  const d = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
  d.setHours(0, 0, 0, 0);
  return d.toISOString();
};

const locale = ["en-GB", "en-US"];

export const titleFormatter = new Intl.DateTimeFormat(locale, {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});
export const shortFormatter = new Intl.DateTimeFormat(locale, {
  year: "numeric",
  month: "short",
  day: "numeric",
});
export const superShortFormatter = new Intl.DateTimeFormat(locale, {
  month: "short",
  day: "numeric",
});

export const superShortFormatterWithWeekday = new Intl.DateTimeFormat(locale, {
  weekday: "long",
  month: "short",
  day: "numeric",
});

export const shortFormatterWithTime = new Intl.DateTimeFormat(locale, {
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});

export const shortDate = (date) => {
  const now = new Date();
  const thisYear = now.getFullYear();
  const showYear =
    date.getFullYear() !== thisYear && now.getTime() - date.getTime() > 6 * 30 * 24 * 3600 * 1000;
  return showYear ? shortFormatter.format(date) : shortFormatterWithTime.format(date);
};

export const superShortDate = (date) => {
  const thisYear = new Date().getFullYear();
  return date.getFullYear() === thisYear
    ? superShortFormatter.format(date)
    : shortFormatter.format(date);
};

export const superShortDateWithWeekdayIfThisYear = (date) => {
  const thisYear = new Date().getFullYear();
  return date.getFullYear() === thisYear
    ? superShortFormatterWithWeekday.format(date)
    : shortFormatter.format(date);
};

export const padLeft2 = (n) => (n < 10 ? `0${n}` : n);
export const toCmpDate = (date) =>
  `${date.getFullYear()}-${padLeft2(date.getMonth() + 1)}-${padLeft2(date.getDate())}`;
export const toDateStr = toCmpDate;

export const addDays = (day, amount) => {
  const next = new Date(day);
  next.setDate(next.getDate() + amount);
  return next;
};

export const MILLISECONDS_IN_DAY = 86400000;
// assumes start of days
export const diffDays = (leftDay, rightDay) => {
  const timestampLeft = leftDay.getTime(); // - getTimezoneOffsetInMilliseconds(leftDay)
  const timestampRight = rightDay.getTime(); // - getTimezoneOffsetInMilliseconds(rightDay)
  return Math.round((timestampLeft - timestampRight) / MILLISECONDS_IN_DAY);
};

export const dayToDateStr = (day) => `${day.year}-${padLeft2(day.month)}-${padLeft2(day.day)}`;

export function isDayAfter(date1, date2) {
  return (
    date1.year > date2.year ||
    (date1.year === date2.year && date1.month > date2.month) ||
    (date1.year === date2.year && date1.month === date2.month && date1.day > date2.day)
  );
}
export function isDayBefore(date1, date2) {
  return (
    date1.year < date2.year ||
    (date1.year === date2.year && date1.month < date2.month) ||
    (date1.year === date2.year && date1.month === date2.month && date1.day < date2.day)
  );
}

export function dateToDay(date) {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
}
export function dayToDate(date) {
  return new Date(date.year, date.month - 1, date.day);
}

export function isDayInPast(day) {
  return isDayBefore(day, dateToDay(new Date()));
}
export function isDayInFuture(day) {
  return isDayBefore(dateToDay(new Date()), day);
}

export function dateStrToDay(dateAsStr) {
  const parts = dateAsStr.split("-");
  return {
    year: parseInt(parts[0], 10),
    month: parseInt(parts[1], 10),
    day: parseInt(parts[2], 10),
  };
}

// based on https://stackoverflow.com/a/6117889
/**
 * @param {Date} date
 * @returns {[year: number, weekNo: number]} year and week number
 * */
export function getWeekAndYear(d) {
  const copyDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  copyDate.setUTCDate(copyDate.getUTCDate() + 4 - (copyDate.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(copyDate.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((copyDate - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [copyDate.getUTCFullYear(), weekNo];
}

// startOfWeek
export function getStartOfMonday(d) {
  const copyDate = new Date(d);
  const day = copyDate.getDay();
  const diff = copyDate.getDate() - day + (day === 0 ? -6 : 1);
  copyDate.setDate(diff);
  return startOfDay(copyDate);
}

export function getNextMorning(days = 1) {
  const now = new Date();
  const target = new Date();

  // Set target time to 8:00
  target.setHours(8, 0, 0, 0);

  // If it's past 3 AM, add a day
  if (now.getHours() >= 3) {
    target.setDate(target.getDate() + 1);
  }

  // Add additional days if specified
  if (days > 1) {
    target.setDate(target.getDate() + (days - 1));
  }

  return target;
}
