import createApi from "./mate";
import {MiniEvent, errorToString} from "@cdx/common";
import {API_ERRORS, cdxRequest} from "./request";
import {dateStrToDay} from "./date-utils";
import {apiErrorEvent} from "./api-error-event";

const context = require.context("../../shared_models/", true, /\.json$/);
const descriptions = context.keys().map(context);

export const uuidEvent = new MiniEvent();
export const mutationEvent = new MiniEvent();

if (process.env.REACT_APP_MODE === "steamy") {
  throw new Error("Nope, you don't wanna load this for steamy!");
}

let currentSessionId = null;
uuidEvent.addListener((uuid) => {
  currentSessionId = uuid;
});

let validVersion = true;

const fetcher = (query, sendResults) => {
  if (!validVersion) sendResults(true);
  cdxRequest({path: "", data: {query}}).then(
    (result) => {
      apiErrorEvent.emit(null);
      sendResults(null, result);
    },
    (e) => {
      apiErrorEvent.emit(e);
      sendResults(true);
    }
  );
};

const sensitiveKeys = new Set(["password", "oldPassword", "token"]);
const strippedData = (data) => {
  return Object.entries(data).reduce((m, [key, val]) => {
    m[key] = sensitiveKeys.has(key) ? "*****" : val;
    return m;
  }, {});
};

const dispatcher = (actionName, data, sendResult) => {
  // eslint-disable-next-line no-console
  console.log("dispatching", actionName, strippedData(data));
  const cleanData = Object.entries(data).reduce(
    (m, [key, val]) => {
      m[key] = typeof val === "string" ? val.trim() : val;
      return m;
    },
    {sessionId: currentSessionId}
  );
  cdxRequest({path: `/dispatch/${actionName}`, data: cleanData}).then(
    (body) => {
      const result = body.payload || {};
      mutationEvent.emit({action: actionName, data, result: result});
      sendResult(null, result);
    },
    (e) => {
      if (e.type === API_ERRORS.API_ERROR) {
        if (e.status === 400) {
          sendResult(e.message.payload?.error);
        } else if (e.status === 401 || e.status === 403) {
          return apiErrorEvent.emit(e);
        }
      }
      sendResult(errorToString(e));
    }
  );
};

const startOptimisticTracker = ({
  modelCache,
  data: {cardId},
  addOptimisticAction,
  mutationId,
  changes,
}) => {
  const {_root, user, account, timeTrackingSegment} = modelCache;
  const me = user[_root.value.loggedInUser];
  const acc = account[_root.value.account];
  if (!me || !acc) return;
  if (acc.value.timeTrackingMode !== "strict") return;
  const userId = me.value.id;
  const accountId = acc.value.id;
  Object.keys(timeTrackingSegment || {})
    .filter((id) => timeTrackingSegment[id] && !timeTrackingSegment[id].value.finishedAt)
    .forEach((id) => {
      addOptimisticAction(
        mutationId,
        {
          type: "delete",
          model: "timeTrackingSegment",
        },
        {id},
        true,
        changes
      );
    });

  const activeTrackerId = me.value.activeTimeTracker;
  if (activeTrackerId) {
    addOptimisticAction(
      mutationId,
      {
        type: "update",
        model: "activeTimeTracker",
      },
      {
        id: activeTrackerId,
        cardId: cardId,
        finishedAt: null,
      },
      true,
      changes
    );
  } else {
    addOptimisticAction(
      mutationId,
      {
        type: "create",
        model: "activeTimeTracker",
        onResolve: ({field: rawField, retVal}) => {
          const field =
            retVal.activeTracker && rawField in retVal.activeTracker ? rawField : `${rawField}Id`;
          if (retVal.activeTracker && field && field in retVal.activeTracker) {
            return {value: retVal.activeTracker[field], retVal: retVal.activeTracker, field};
          }
        },
      },
      {card: cardId, user: userId, account: accountId},
      true,
      changes
    );
  }
  const mutId = `$opt$${mutationId}`;
  addOptimisticAction(
    mutationId,
    {
      type: "update",
      model: "user",
      onResolve: ({field, retVal}) => {
        if (field === "activeTimeTracker" && retVal.activeTracker)
          return {value: retVal.activeTracker.id, retVal};
      },
    },
    {id: userId, activeTimeTracker: mutId},
    true,
    changes
  );
  addOptimisticAction(
    mutationId,
    {
      type: "create",
      model: "timeTrackingSegment",
      onResolve: ({field: rawField, retVal}) => {
        const field =
          retVal.activeTracker && rawField in retVal.activeTracker ? rawField : `${rawField}Id`;
        if (field && retVal.startedSegment && field in retVal.startedSegment) {
          let value = retVal.startedSegment[field];
          if (field === "startedAt") value = new Date(value);
          return {value, retVal: retVal.startedSegment};
        }
      },
    },
    {
      card: cardId,
      user: userId,
      account: accountId,
      startedAt: new Date(),
      modifyDurationMsBy: 0,
      finishedAt: null,
    },
    true,
    changes
  );
  const firstTtsField = `timeTrackingSegments({"$first":true,"$order":"createdAt","finishedAt":null,"userId":"${userId}"})`;
  const ttsFields = `timeTrackingSegments({"userId":"${userId}"})`;
  addOptimisticAction(
    mutationId,
    {
      type: "update",
      model: "card",
      onResolve: ({field, retVal}) => {
        if (field === firstTtsField && retVal.startedSegment)
          return {value: retVal.startedSegment.id, retVal};
      },
    },
    {id: cardId, [firstTtsField]: mutId, [ttsFields]: undefined},
    true,
    changes
  );
};

const stopOptimisticTracker = ({
  modelCache,
  data: {cardId},
  addOptimisticAction,
  mutationId,
  changes,
}) => {
  const {_root, user, account, timeTrackingSegment} = modelCache;
  const me = user[_root.value.loggedInUser];
  const acc = account[_root.value.account];
  if (!me || !acc) return;
  if (acc.value.timeTrackingMode !== "strict") return;
  Object.keys(timeTrackingSegment || {})
    .filter(
      (id) =>
        timeTrackingSegment[id] &&
        !timeTrackingSegment[id].value.finishedAt &&
        timeTrackingSegment[id].value.card === cardId
    )
    .forEach((id) => {
      addOptimisticAction(
        mutationId,
        {
          type: "update",
          model: "timeTrackingSegment",
        },
        {id, finishedAt: new Date()},
        true,
        changes
      );
      addOptimisticAction(
        mutationId,
        {
          type: "delete",
          model: "timeTrackingSegment",
        },
        {id},
        true,
        changes
      );
    });
};

const handUpdates = [
  {desc: {type: "create", model: "queueEntry"}, data: {}},
  {desc: {type: "update", model: "queueEntry"}, data: {sortIndex: undefined}},
];

const singleCardUpdate = (
  {
    id,
    deckId,
    assigneeId,
    content,
    visibility,
    milestoneId,
    sprintId,
    status,
    childCards,
    addMasterTag,
    removeMasterTag,
    inDeps,
    outDeps,
    isDoc,
    parentCardId,
    coverFileModifier,
  },
  {_isOptimistic, _getModelFromCache}
) => {
  // card might be null!
  const card = _getModelFromCache("card", id);
  const archiveActions = () => {
    const actions = [
      {desc: {type: "update", model: "resolvable"}, data: {isClosed: undefined}},
      {desc: {type: "delete", model: "activeTimeTracker"}, data: {cardId: id}},
      ...handUpdates,
    ];
    if (card && card.childCards) {
      actions.push(
        ...card.childCards.map((childId) => ({
          desc: {type: "update", model: "card"},
          data: {cardId: childId, visibility},
        }))
      );
    } else {
      actions.push({desc: {type: "update", model: "card"}, data: {visibility: undefined}});
    }
    return actions;
  };

  const deleteActions = () => {
    const actions = [
      ...handUpdates,
      {
        desc: {type: "update", model: "card"},
        data: {cardId: id, inDeps: undefined, outDeps: undefined, hasBlockingDeps: undefined},
      },
      {
        desc: {type: "delete", model: "handCard"},
        data: {cardId: id},
      },
    ];
    if (card && "parentCard" in card) {
      if (card.parentCard) {
        actions.push(
          {desc: {type: "update", model: "card"}, data: {cardId: card.parentCard, childCards: []}},
          {desc: {type: "update", model: "card"}, data: {cardId: id, parentCardId: null}}
        );
      }
    } else {
      actions.push(
        {desc: {type: "update", model: "card"}, data: {childCards: []}},
        {desc: {type: "update", model: "card"}, data: {cardId: id, parentCardId: null}}
      );
    }
    if (card && card.childCards) {
      actions.push({desc: {type: "update", model: "card"}, data: {cardId: id, childCards: []}});
    }
    return actions;
  };

  const childCardUpdateActions = () => {
    const actions = [
      {
        desc: {type: "update", model: "card"},
        data: {cardId: id, effort: 0, status: undefined, isDoc: undefined},
      },
      ...handUpdates,
    ];
    // in case a started parent got children
    if (card && (card.status === "started" || !card.status)) {
      actions.push(
        {desc: {type: "delete", model: "activeTimeTracker"}, data: {cardId: id}},
        {desc: {type: "update", model: "timeTrackingSegment"}, data: {finishedAt: undefined}},
        {desc: {type: "delete", model: "timeTrackingSegment"}, data: {}},
        {
          desc: {type: "update", model: "timeTrackingSum"},
          data: {
            sumMs: undefined,
            runningModifyDurationMsBy: undefined,
            runningStartedAt: undefined,
          },
        },
        {desc: {type: "delete", model: "timeTrackingSum"}, data: {}}
      );
    }

    const nextChildCardSet = new Set(childCards || []);

    const prevChildren = (card && card.childCards) || [];
    prevChildren.forEach((cId) => {
      if (!nextChildCardSet.has(cId)) {
        // child got removed
        actions.push({
          desc: {type: "update", model: "card"},
          data: {cardId: cId, parentCardId: null},
        });
      }
    });

    let unknownExParent = false;
    (childCards || []).forEach((childId) => {
      const child = _getModelFromCache("card", childId);
      if (!child) return;
      if ("cardParent" in child) {
        if (child.cardParent) {
          actions.push({
            desc: {type: "update", model: "card"},
            data: {
              cardId: child.cardParent,
              childCards: undefined,
              status: undefined,
              effort: undefined,
              isDoc: undefined,
            },
          });
        }
      } else {
        unknownExParent = true;
      }
      actions.push({
        desc: {type: "update", model: "card"},
        data: {cardId: childId, parentCardId: id},
      });
    });
    if (unknownExParent) {
      actions.push({
        desc: {type: "update", model: "card"},
        data: {childCards: undefined, status: undefined, effort: undefined},
      });
    }

    return actions;
  };

  const updates = [
    {desc: {type: "create", model: "cardHistory"}, data: {cardId: id}},
    {desc: {type: "create", model: "activity"}, data: {cardId: id, deckId, milestoneId, sprintId}},
  ];

  if (childCards) updates.push(...childCardUpdateActions());
  if (parentCardId !== undefined && card.parentCard) {
    updates.push({
      desc: {type: "update", model: "card"},
      data: {cardId: card.parentCard, childCards: undefined},
    });
  }
  if (sprintId !== undefined) {
    updates.push({
      desc: {type: "update", model: "card"},
      data: {meta: undefined, milestoneId: undefined},
    });
  }
  if (deckId) {
    updates.push(
      {desc: {type: "create", model: "deckAssignment"}, data: {deckId}},
      {desc: {type: "update", model: "deckAssignment"}, data: {deckId, lastAssignedAt: undefined}},
      {
        desc: {type: "update", model: "card"},
        data: {id, deckId, assigneeId: undefined, milestoneId: undefined, sprintId: undefined},
      },
      {
        desc: {type: "update", model: "resolvableParticipant"},
        data: {done: undefined, lastChangedAt: undefined},
      },
      {desc: {type: "create", model: "activity"}, data: {cardId: undefined}},
      {
        desc: {type: "update", model: "cardOrder"},
        data: {cardId: id, context: "deck", sortValue: undefined, label: undefined},
      }
    );
  }
  if (assigneeId) {
    updates.push(
      {desc: {type: "create", model: "assigneeAssignment"}, data: {assigneeId}},
      {
        desc: {type: "update", model: "assigneeAssignment"},
        data: {deckId, lastAssignedAt: undefined},
      }
    );
  }
  if (milestoneId) {
    updates.push(...handUpdates, {
      desc: {type: "update", model: "cardOrder"},
      data: {cardId: id, context: "milestone", sortValue: undefined, label: undefined},
    });
  }
  if (sprintId) {
    updates.push({
      desc: {type: "update", model: "cardOrder"},
      data: {cardId: id, context: "sprint", sortValue: undefined, label: undefined},
    });
  }
  if (visibility === "deleted" || visibility === "archived") updates.push(...archiveActions());
  if (visibility === "deleted") updates.push(...deleteActions());
  if (visibility === "default") updates.push(...handUpdates);
  if (content) {
    updates.push(
      {desc: {type: "create", model: "userTag"}, data: {}},
      {desc: {type: "update", model: "card"}, data: {cardReferences: undefined}}
    );
  }
  if (card && coverFileModifier !== undefined) {
    updates.push({
      desc: {type: "update", model: "card"},
      data: {id, meta: {...card.meta, coverFileModifier}},
    });
  }
  if (status === "started") {
    updates.push(
      {
        desc: {type: "update", model: "timeTrackingSum"},
        data: {
          sumMs: undefined,
          runningModifyDurationMsBy: undefined,
          runningStartedAt: undefined,
        },
      },
      {desc: {type: "delete", model: "timeTrackingSum"}, data: {}},
      ...(_isOptimistic
        ? [{desc: {type: "custom", fn: startOptimisticTracker}, data: {cardId: id}}]
        : []),
      {desc: {type: "create", model: "queueEntry"}, data: {}},
      {
        desc: {type: "update", model: "queueEntry"},
        data: {cardDoneAt: undefined, sortIndex: undefined},
      }
    );
  }
  if (status || visibility) {
    updates.push({desc: {type: "update", model: "card"}, data: {hasBlockingDeps: undefined}});
  }
  if (isDoc) {
    updates.push({
      desc: {type: "update", model: "card"},
      data: {
        cardId: id,
        effort: null,
        priority: null,
        inDeps: [],
        outDeps: [],
        hasBlockingDeps: false,
        meta: undefined,
      },
    });
    if (card && (card.inDeps || card.outDeps)) {
      updates.push({
        desc: {type: "update", model: "card"},
        data: {inDeps: undefined, outDeps: undefined, hasBlockingDeps: undefined},
      });
    }
  }

  if (status === "started") {
    updates.push(
      {desc: {type: "create", model: "activeTimeTracker"}, data: {cardId: id}},
      {desc: {type: "update", model: "activeTimeTracker"}, data: {cardId: id}},
      {desc: {type: "create", model: "timeTrackingSegment"}, data: {}},
      {desc: {type: "update", model: "card"}, data: {id, sprintId: undefined}}
    );
  }

  const couldStopTimer =
    assigneeId ||
    assigneeId === null ||
    status === "done" ||
    status === "not_started" ||
    status === null ||
    isDoc;
  if (couldStopTimer) {
    updates.push(
      ...(_isOptimistic
        ? [{desc: {type: "custom", fn: stopOptimisticTracker}, data: {cardId: id}}]
        : []),
      {desc: {type: "delete", model: "activeTimeTracker"}, data: {cardId: id}},
      {desc: {type: "update", model: "timeTrackingSegment"}, data: {finishedAt: undefined}},
      {desc: {type: "delete", model: "timeTrackingSegment"}, data: {}},
      {
        desc: {type: "update", model: "timeTrackingSum"},
        data: {
          sumMs: undefined,
          runningModifyDurationMsBy: undefined,
          runningStartedAt: undefined,
        },
      },
      {desc: {type: "delete", model: "timeTrackingSum"}, data: {}},
      // also affect queue entries
      {desc: {type: "create", model: "queueEntry"}, data: {}},
      {
        desc: {type: "update", model: "queueEntry"},
        data: {cardDoneAt: undefined, sortIndex: undefined},
      }
    );
  }

  if (addMasterTag || removeMasterTag) {
    updates.push({desc: {type: "update", model: "card"}, data: {id, masterTags: undefined}});
  }
  if (card && card.parentCard) {
    updates.push({
      desc: {type: "update", model: "card"},
      data: {id: card && card.parentCard, status: undefined, isDoc: undefined},
    });
  }
  if (inDeps || outDeps) {
    updates.push({
      desc: {type: "update", model: "card"},
      data: {inDeps: undefined, outDeps: undefined, hasBlockingDeps: undefined},
    });
  }

  return updates;
};

const mutations = {
  "cards.create": {
    type: "create",
    model: "card",
    convertDataForOptimistic: (data) => ({
      // convert "2016-11-23" to {year: 2016, month: 11, day: 23}
      ...data,
      dueDate: data.dueDate && dateStrToDay(data.dueDate),
    }),
    implicit: ({
      attachments,
      deckId,
      milestoneId,
      userId,
      parentCardId,
      assigneeId,
      addAsBookmark,
      putInQueue,
      orderInfo,
    }) => [
      {desc: {type: "create", model: "activity"}, data: {deckId, milestoneId}},
      {desc: {type: "create", model: "userTag"}, data: {}},
      ...(!deckId || addAsBookmark
        ? [{desc: {type: "create", model: "handCard"}, data: {userId}}]
        : []),
      ...(assigneeId || putInQueue
        ? [...handUpdates, {desc: {type: "create", model: "assigneeAssignment"}, data: {}}]
        : []),
      ...(attachments && attachments.length
        ? [{desc: {type: "update", model: "account"}, data: {totalMediaByteUsage: null}}]
        : []),
      ...(orderInfo ? [{desc: {type: "create", model: "cardOrder"}, data: {}}] : []),
      ...(parentCardId
        ? [
            {
              desc: {type: "update", model: "card"},
              data: {
                id: parentCardId,
                status: undefined,
                effort: undefined,
                isDoc: undefined,
                childCards: undefined,
              },
            },
          ]
        : []),
      ...(deckId
        ? [
            {desc: {type: "create", model: "deckAssignment"}, data: {deckId}},
            {
              desc: {type: "update", model: "deckAssignment"},
              data: {deckId, lastAssignedAt: undefined},
            },
          ]
        : []),
    ],
  },
  "cards.bulkCreate": {
    type: "create",
    model: "card",
    implicit: () => [{desc: {type: "create", model: "activity"}, data: {}}],
  },
  "cards.update": {
    type: "update",
    model: "card",
    convertDataForOptimistic: (data) => ({
      // convert "2016-11-23" to {year: 2016, month: 11, day: 23}
      ...data,
      dueDate: data.dueDate && dateStrToDay(data.dueDate),
    }),
    implicit: singleCardUpdate,
  },
  "cards.bulkUpdate": {
    type: "update",
    model: "card",
    implicit: ({ids, ...rest}, retVal) =>
      (ids || []).reduce((l, id) => {
        l.push(...singleCardUpdate({id, ...rest}, retVal));
        return l;
      }, []),
  },
  "cards.updateMany": {
    type: "update",
    model: "card",
    implicit: ({cards}, retVal) =>
      (cards || []).reduce((l, card) => {
        l.push(
          {desc: {type: "update", model: "card"}, data: card},
          ...singleCardUpdate(card, retVal)
        );
        return l;
      }, []),
  },
  "cards.copy": {
    type: "create",
    model: "card",
    implicit: ({props, target, userId}) => [
      {
        desc: {type: "create", model: "card"},
        data: props.reduce((m, p) => {
          m[p] = null;
          return m;
        }, {}),
      },
      {desc: {type: "create", model: "activity"}, data: {}},
      {
        desc: {type: "create", model: "card"},
        data: {deckId: target === "hand" ? undefined : target},
      },
      ...(target === "hand" ? [{desc: {type: "create", model: "handCard"}, data: {userId}}] : []),
      ...(props.attachments
        ? [{desc: {type: "update", model: "account"}, data: {totalMediaByteUsage: null}}]
        : []),
    ],
  },

  "cards.addFile": {
    type: "create",
    model: "attachment",
    implicit: ({cardId}) => [
      {
        desc: {type: "update", model: "card"},
        data: {attachments: undefined, cardId, coverFileId: undefined},
      },
      {desc: {type: "create", model: "activity"}, data: {cardId}},
      {desc: {type: "create", model: "cardHistory"}, data: {cardId}},
      {desc: {type: "update", model: "account"}, data: {totalMediaByteUsage: undefined}},
    ],
  },
  "cards.seen": {
    type: "void",
    implicit: ({cardId}) => [
      {desc: {type: "update", model: "card"}, data: {cardId, meta: undefined}},
    ],
  },

  "files.create": {
    type: "create",
    model: "file",
  },

  "decks.create": {
    type: "create",
    model: "deck",
    implicit: ({userId}, {id}) => [
      {
        desc: {type: "create", model: "deckSubscription"},
        data: {userId, deckId: id},
      },
      {desc: {type: "create", model: "activity"}, data: {}},
    ],
  },
  "decks.update": {
    type: "update",
    model: "deck",
    implicit: ({id, milestoneId, coverFileData, handSyncEnabled, defaultProjectTagId}) => [
      ...(coverFileData
        ? [{desc: {type: "update", model: "deck"}, data: {coverFileId: undefined, id}}]
        : []),
      ...(milestoneId !== undefined
        ? [{desc: {type: "update", model: "card"}, data: {milestoneId}}]
        : []),
      ...(handSyncEnabled ? handUpdates : []),
      ...(defaultProjectTagId || defaultProjectTagId === null
        ? [
            {
              desc: {type: "update", model: "card"},
              data: {masterTags: undefined, content: undefined, title: undefined},
            },
          ]
        : []),
    ],
  },
  "decks.updateCover": {
    type: "update",
    model: "deck",
    implicit: ({id}) => [
      {desc: {type: "update", model: "deck"}, data: {coverFileId: undefined, id}},
    ],
  },
  "decks.delete": {
    type: "update",
    model: "deck",
    implicit: ({id}) => [{desc: {type: "update", model: "deck"}, data: {isDeleted: true, id}}],
  },
  // called by the server only
  "decks.updateStats": {
    type: "void",
    implicit: ({id}) => [{desc: {type: "update", model: "deck"}, data: {id, stats: undefined}}],
  },

  "decks.updateGuardians": {
    type: "void",
    implicit: ({id, userIds}) => [
      {desc: {type: "update", model: "deck"}, data: {id, hasGuardians: userIds.length > 0}},
      {desc: {type: "create", model: "deckGuardian"}, data: {deckId: id}},
      {desc: {type: "delete", model: "deckGuardian"}, data: {deckId: id}},
    ],
  },

  "decks.addToSpaceBefore": {
    type: "void",
    implicit: ({deckIds, targetSpaceId, targetProjectId}) => [
      ...deckIds.map((id) => ({
        desc: {type: "update", model: "deck"},
        data: {
          id,
          projectId: targetProjectId,
          milestoneId: undefined,
          sortValue: undefined,
          defaultProjectTagId: undefined,
          spaceId: targetSpaceId,
        },
      })),
      {
        desc: {type: "update", model: "card"},
        data: {assigneeId: undefined, milestoneId: undefined, sprintId: undefined},
      },
      {
        desc: {type: "update", model: "resolvableParticipant"},
        data: {done: undefined, lastChangedAt: undefined},
      },
      {desc: {type: "create", model: "activity"}, data: {}},
    ],
  },

  "decks.addToSpaceAfter": {
    type: "void",
    implicit: ({deckIds, targetSpaceId, targetProjectId}) => [
      ...deckIds.map((id) => ({
        desc: {type: "update", model: "deck"},
        data: {
          id,
          projectId: targetProjectId,
          milestoneId: undefined,
          defaultProjectTagId: undefined,
          sortValue: undefined,
          spaceId: targetSpaceId,
        },
      })),
      {
        desc: {type: "update", model: "card"},
        data: {assigneeId: undefined, milestoneId: undefined, sprintId: undefined},
      },
      {
        desc: {type: "update", model: "resolvableParticipant"},
        data: {done: undefined, lastChangedAt: undefined},
      },
      {desc: {type: "create", model: "activity"}, data: {}},
    ],
  },

  "accounts.update": {
    type: "update",
    model: "account",
    implicit: ({id}) => [
      {
        desc: {type: "update", model: "stripeAccountSync"},
        data: {id, euVatIdData: undefined, vatTaxPercentage: undefined},
      },
      {
        desc: {type: "create", model: "stripeAccountSync"},
        data: {id, euVatIdData: undefined, vatTaxPercentage: undefined},
      },
    ],
  },
  "accounts.updateSettings": {
    type: "update",
    model: "accountUserSetting",
  },
  "accounts.disable": {
    type: "update",
    model: "account",
    implicit: ({id}) => [
      {
        desc: {type: "update", model: "account"},
        data: {id, disabledBy: undefined, isDisabled: undefined, disabledAt: undefined},
      },
    ],
  },

  "projects.create": {
    type: "create",
    model: "project",
    implicit: () => [
      {desc: {type: "create", model: "projectOrder"}, data: {}},
      {desc: {type: "create", model: "projectSelection"}, data: {}},
      {desc: {type: "update", model: "account"}, data: {activeProjectCount: undefined}},
    ],
  },
  "projects.createWithDecks": {
    type: "create",
    model: "project",
    implicit: () => [
      {desc: {type: "create", model: "projectOrder"}, data: {}},
      {desc: {type: "create", model: "projectSelection"}, data: {}},
      {desc: {type: "create", model: "deck"}, data: {}},
      {desc: {type: "create", model: "deckSubscription"}, data: {}},
      {desc: {type: "create", model: "activity"}, data: {}},
      {desc: {type: "update", model: "account"}, data: {activeProjectCount: undefined}},
    ],
  },
  "projects.update": {
    type: "update",
    model: "project",
    implicit: ({id, explicitUserAccess}) => [
      ...(explicitUserAccess !== undefined
        ? [
            {
              desc: {type: "create", model: "projectUser"},
              data: {},
            },
            {
              desc: {type: "update", model: "projectUser"},
              data: {projectRole: undefined, projectId: id},
            },
            {
              desc: {type: "create", model: "userProjectAccess"},
              data: {},
            },
            {
              desc: {type: "update", model: "userProjectAccess"},
              data: {projectRole: undefined, projectId: id},
            },
          ]
        : []),
    ],
  },
  "projects.setVisibility": {
    type: "update",
    model: "project",
    implicit: ({id}) => [
      {desc: {type: "delete", model: "projectSelection"}, data: {}},
      {desc: {type: "update", model: "project"}, data: {isPublic: undefined}},
      {desc: {type: "update", model: "account"}, data: {activeProjectCount: undefined}},
      {desc: {type: "update", model: "queueEntry"}, data: {sortIndex: undefined}},
      {desc: {type: "delete", model: "queueEntry"}, data: {}},
    ],
  },
  "projects.updateCover": {
    type: "update",
    model: "project",
    implicit: ({id}) => [
      {desc: {type: "update", model: "project"}, data: {coverFileId: undefined, id}},
    ],
  },
  "projects.addTag": {
    type: "create",
    model: "projectTag",
    implicit: () => [
      {desc: {type: "update", model: "card"}, data: {masterTags: undefined}},
      {desc: {type: "delete", model: "userTag"}, data: {}},
    ],
  },
  "projects.deleteTag": {
    type: "delete",
    model: "projectTag",
  },
  "projects.deleteTags": {
    type: "delete",
    model: "projectTag",
  },
  "projects.updateTag": {
    type: "update",
    model: "projectTag",
    implicit: () => [
      {desc: {type: "update", model: "card"}, data: {masterTags: undefined, content: undefined}},
      {desc: {type: "delete", model: "userTag"}, data: {}},
    ],
  },
  "projects.copyTags": {
    type: "create",
    model: "projectTag",
    implicit: () => [
      {desc: {type: "update", model: "card"}, data: {masterTags: undefined}},
      {desc: {type: "delete", model: "userTag"}, data: {}},
    ],
  },
  "projects.updateOrder": {
    type: "create",
    model: "projectOrder",
    implicit: () => [{desc: {type: "update", model: "projectOrder"}, data: {sortIndex: undefined}}],
  },

  "cardPresets.create": {
    type: "create",
    model: "cardPreset",
  },
  "cardPresets.delete": {
    type: "delete",
    model: "cardPreset",
  },

  "workflows.apply": {
    type: "create",
    model: "card",
    implicit: ({id}) => [
      {
        desc: {type: "update", model: "card"},
        data: {id, childCards: undefined, status: undefined, visibility: undefined},
      },
    ],
  },

  "workflows.createItem": {
    type: "create",
    model: "workflowItem",
  },
  "workflows.updateItem": {
    type: "update",
    model: "workflowItem",
    implicit: ({itemId, inDeps, outDeps, visibility}) => [
      {desc: {type: "create", model: "workflowItemHistory"}, data: {itemId}},
      ...(inDeps || outDeps || visibility
        ? [
            {
              desc: {type: "update", model: "workflowItem"},
              data: {inDeps: undefined, outDeps: undefined},
            },
          ]
        : []),
    ],
  },
  "workflows.bulkUpdateItems": {
    type: "update",
    model: "workflowItem",
    implicit: ({ids, addMasterTag, removeMasterTag, deckId}) => [
      ...ids.map((itemId) => ({
        desc: {type: "create", model: "workflowItemHistory"},
        data: {itemId},
      })),
      ...(deckId
        ? [{desc: {type: "update", model: "workflowItem"}, data: {inDeps: [], outDeps: []}}]
        : []),
      ...(addMasterTag || removeMasterTag
        ? ids.map((itemId) => ({
            desc: {type: "update", model: "workflowItem"},
            data: {itemId, masterTags: undefined},
          }))
        : []),
    ],
  },
  "workflows.copyWorkflowItems": {
    type: "create",
    model: "workflowItem",
    implicit: ({deckId}) => [
      {
        desc: {type: "update", model: "deck"},
        data: {id: deckId, workflowItemOrderLabels: undefined},
      },
    ],
  },
  "workflows.createDependencyChain": {
    type: "custom",
    fn: () => {},
    implicit: ({ids}) => [
      {
        desc: {type: "update", model: "workflowItem"},
        data: {ids, inDeps: undefined, outDeps: undefined},
      },
    ],
  },

  "workflows.addItemsAfter": {
    type: "void",
    implicit: ({itemIds, label}) => [
      ...itemIds.map((itemId) => ({
        desc: {type: "update", model: "workflowItem"},
        data: {itemId, label, sortValue: undefined},
      })),
    ],
  },
  "workflows.addItemsBefore": {
    type: "void",
    implicit: ({itemIds, label}) => [
      ...itemIds.map((itemId) => ({
        desc: {type: "update", model: "workflowItem"},
        data: {itemId, label, sortValue: undefined},
      })),
    ],
  },

  "attachments.deleteFile": {
    type: "update",
    model: "attachment",
    implicit: ({fileId}) => [
      {
        desc: {type: "update", model: "file"},
        data: {id: fileId, isDeleted: true, deletedBy: undefined, deletedAt: undefined},
      },
      {desc: {type: "update", model: "account"}, data: {totalMediaByteUsage: undefined}},
    ],
  },
  "attachments.batchDeleteFile": {
    type: "update",
    model: "attachment",
    implicit: ({fileIds}) => [
      ...fileIds.map((fileId) => ({
        desc: {type: "update", model: "file"},
        data: {id: fileId, isDeleted: true, deletedBy: undefined, deletedAt: undefined},
      })),
      {desc: {type: "update", model: "account"}, data: {totalMediaByteUsage: undefined}},
    ],
  },
  "attachments.update": {
    type: "update",
    model: "attachment",
  },
  "attachments.delete": {
    type: "delete",
    model: "attachment",
    implicit: ({cardId}) => [
      {
        desc: {type: "update", model: "card"},
        data: {attachments: undefined, coverFileId: undefined, cardId},
      },
      {desc: {type: "create", model: "cardHistory"}, data: {cardId}},
      {desc: {type: "create", model: "activity"}, data: {cardId}},
    ],
  },

  "resolvables.create": {
    type: "create",
    model: "resolvable",
    implicit: ({cardId}) => [
      {desc: {type: "update", model: "card"}, data: {status: undefined, cardId}},
      {desc: {type: "create", model: "resolvableEntry"}, data: {cardId}},
      {desc: {type: "create", model: "activity"}, data: {cardId}},
      {desc: {type: "update", model: "timeTrackingSegment"}, data: {finishedAt: undefined}},
      {desc: {type: "delete", model: "timeTrackingSegment"}, data: {}},
      {
        desc: {type: "update", model: "timeTrackingSum"},
        data: {
          sumMs: undefined,
          runningModifyDurationMsBy: undefined,
          runningStartedAt: undefined,
        },
      },
      {desc: {type: "delete", model: "timeTrackingSum"}, data: {}},
      {desc: {type: "delete", model: "activeTimeTracker"}, data: {cardId}},
      {desc: {type: "create", model: "resolvableParticipant"}, data: {cardId}},
      ...handUpdates,
    ],
  },
  "resolvables.close": {
    type: "update",
    model: "resolvable",
    implicit: ({id}) => [
      {
        desc: {type: "update", model: "resolvable"},
        data: {id, closedAt: undefined, isClosed: true, reopenedBy: null, reopenedAt: null},
      },
      {desc: {type: "update", model: "timeTrackingSegment"}, data: {finishedAt: undefined}},
      {desc: {type: "delete", model: "timeTrackingSegment"}, data: {}},
      {
        desc: {type: "update", model: "timeTrackingSum"},
        data: {
          sumMs: undefined,
          runningModifyDurationMsBy: undefined,
          runningStartedAt: undefined,
        },
      },
      {desc: {type: "delete", model: "timeTrackingSum"}, data: {}},
    ],
  },
  "resolvables.reopen": {
    type: "update",
    model: "resolvable",
    implicit: ({id}) => [
      {desc: {type: "update", model: "timeTrackingSegment"}, data: {finishedAt: undefined}},
      {desc: {type: "delete", model: "timeTrackingSegment"}, data: {}},
      {
        desc: {type: "update", model: "resolvable"},
        data: {
          id,
          closedAt: undefined,
          isClosed: false,
          reopenedBy: undefined,
          reopenedAt: undefined,
        },
      },
      {
        desc: {type: "update", model: "timeTrackingSum"},
        data: {
          sumMs: undefined,
          runningModifyDurationMsBy: undefined,
          runningStartedAt: undefined,
        },
      },
      {desc: {type: "delete", model: "timeTrackingSum"}, data: {}},
      {desc: {type: "delete", model: "activeTimeTracker"}, data: {}},
      {desc: {type: "update", model: "card"}, data: {status: undefined}},
    ],
  },
  "resolvables.comment": {
    type: "create",
    model: "resolvableEntry",
    implicit: ({resolvableId}) => [
      {desc: {type: "create", model: "resolvableParticipant"}, data: {resolvableId}},
      {desc: {type: "create", model: "activity"}, data: {}},
      {
        desc: {type: "update", model: "resolvableParticipant"},
        data: {done: undefined, lastChangedAt: undefined, resolvableId, pinned: undefined},
      },
      {desc: {type: "create", model: "resolvableParticipantHistory"}, data: {resolvableId}},
    ],
  },
  "resolvables.updateComment": {
    type: "update",
    model: "resolvableEntry",
    implicit: ({entryId, resolvableId}) => [
      {desc: {type: "create", model: "resolvableParticipant"}, data: {resolvableId}},
      {desc: {type: "create", model: "activity"}, data: {}},
      {
        desc: {type: "update", model: "resolvableParticipant"},
        data: {done: undefined, lastChangedAt: undefined, resolvableId},
      },
      {desc: {type: "create", model: "resolvableParticipantHistory"}, data: {resolvableId}},
      {desc: {type: "create", model: "resolvableEntryHistory"}, data: {entryId}},
    ],
  },
  "resolvables.updateParticipantDone": {
    type: "update",
    model: "resolvableParticipant",
    implicit: ({id, resolvableId}) => [
      {
        desc: {type: "update", model: "resolvableParticipant"},
        data: {lastChangedAt: undefined, id, pinned: undefined},
      },
      {desc: {type: "create", model: "resolvableParticipantHistory"}, data: {resolvableId}},
    ],
  },
  "resolvables.updateResolvablePinned": {
    type: "update",
    model: "resolvableParticipant",
  },

  "users.update": {
    type: "update",
    model: "user",
    implicit: ({
      id,
      profileImageData,
      wantsWeeklyDigestMail,
      timelineScaleTypeOverwrite,
      startWeekdayOverwrite,
    }) => [
      ...(wantsWeeklyDigestMail !== undefined ||
      timelineScaleTypeOverwrite !== undefined ||
      startWeekdayOverwrite !== undefined
        ? [
            {
              desc: {type: "update", model: "accountUserSetting"},
              data: {wantsWeeklyDigestMail, timelineScaleTypeOverwrite, startWeekdayOverwrite},
            },
          ]
        : []),
      ...(profileImageData
        ? [{desc: {type: "update", model: "user"}, data: {profileImageId: undefined, id}}]
        : []),
    ],
  },
  "users.changePassword": {
    type: "update",
    model: "user",
    implicit: ({id}) => [{desc: {type: "update", model: "user"}, data: {id, hasPassword: true}}],
  },
  "users.login": {
    type: "create",
    model: "user",
  },
  "users.logout": {
    type: "delete",
    model: "user",
  },

  "users.sendInvitation": {
    type: "create",
    model: "userInvitation",
    implicit: (_, {type}) =>
      type === "user"
        ? [
            {desc: {type: "create", model: "accountRole"}, data: {}},
            {desc: {type: "update", model: "account"}, data: {seats: undefined}},
          ]
        : [],
  },
  "users.revokeInvitation": {
    type: "delete",
    model: "userInvitation",
  },
  "users.resendInvitation": {
    type: "update",
    model: "userInvitation",
    implicit: () => [
      {desc: {type: "update", model: "userInvitation"}, data: {createdAt: undefined}},
    ],
  },
  "users.joinAccount": {
    type: "create",
    model: "user",
    implicit: ({accountId}) => [
      {desc: {type: "create", model: "accountRole"}, data: {accountId}},
      {
        desc: {type: "update", model: "account"},
        data: {accountId, seats: undefined},
      },
      {desc: {type: "delete", model: "userInvitation"}, data: {accountId}},
    ],
  },
  "users.requestPasswordReset": {
    type: "update",
    model: "user",
  },
  "users.resetPassword": {
    type: "update",
    model: "user",
  },
  "users.updateUserSetting": {
    type: "update",
    model: "userSetting",
  },

  "roles.update": {
    type: "update",
    model: "accountRole",
    implicit: ({userId}) => [
      {desc: {type: "delete", model: "userProjectAccess"}, data: {userId}},
      {desc: {type: "delete", model: "projectUser"}, data: {userId}},
      {desc: {type: "update", model: "account"}, data: {seats: undefined}},
      {desc: {type: "update", model: "accountRole"}, data: {role: undefined}}, // setting an owner, de-owns myself
    ],
  },
  "roles.disable": {
    type: "update",
    model: "accountRole",
    implicit: ({userId}) => [
      {desc: {type: "delete", model: "userProjectAccess"}, data: {userId}},
      {desc: {type: "delete", model: "projectUser"}, data: {userId}},
      {desc: {type: "update", model: "account"}, data: {seats: undefined}},
      {desc: {type: "update", model: "accountRole"}, data: {userId, role: undefined}},
    ],
  },
  "roles.reEnable": {
    type: "update",
    model: "accountRole",
    implicit: ({userId}) => [
      {desc: {type: "delete", model: "userProjectAccess"}, data: {userId}},
      {desc: {type: "delete", model: "projectUser"}, data: {userId}},
      {desc: {type: "update", model: "account"}, data: {seats: undefined}},
      {desc: {type: "update", model: "accountRole"}, data: {userId, role: undefined}},
    ],
  },
  "roles.delete": {
    type: "delete",
    model: "accountRole",
    implicit: ({userId}) => [
      {desc: {type: "delete", model: "userProjectAccess"}, data: {userId}},
      {desc: {type: "delete", model: "projectUser"}, data: {userId}},
      {desc: {type: "update", model: "account"}, data: {seats: undefined}},
      {desc: {type: "update", model: "accountRole"}, data: {userId, role: undefined}},
    ],
  },
  // no via, since to the receiving user it's like actually adding/removing a project
  "roles.addProjectAccess": {
    type: "create",
    model: "projectUser",
    implicit: ({userId, projectId}) => [
      {desc: {type: "create", model: "userProjectAccess"}, data: {userId, projectId}},
    ],
  },
  "roles.revokeProjectAccess": {
    type: "delete",
    model: "projectUser",
    implicit: ({userId, projectId}) => [
      {desc: {type: "delete", model: "userProjectAccess"}, data: {userId, projectId}},
    ],
  },

  "userEmails.add": {
    type: "create",
    model: "userEmail",
  },
  "userEmails.delete": {
    type: "delete",
    model: "userEmail",
  },
  "userEmails.setPrimary": {
    type: "update",
    model: "userEmail",
    implicit: () => [{desc: {type: "update", model: "userEmail"}, data: {isPrimary: undefined}}],
  },
  "userEmails.resendVerification": {
    type: "update",
    model: "userEmail",
  },
  "userEmails.verify": {
    type: "update",
    model: "userEmail",
    implicit: (_, {id}) => [
      {desc: {type: "update", model: "userEmail"}, data: {isVerified: undefined, id}},
      {desc: {type: "create", model: "accountRole"}, data: {}},
      {desc: {type: "delete", model: "userInvitation"}, data: {}},
    ],
  },

  "inviteCodes.create": {
    type: "create",
    model: "userInviteCode",
  },
  "inviteCodes.disable": {
    type: "void",
    implicit: ({id}) => [
      {
        desc: {type: "update", model: "userInviteCode"},
        data: {id, isActive: false},
      },
    ],
  },

  "inviteCodes.apply": {
    type: "void",
    implicit: () => [
      {
        desc: {type: "update", model: "userInviteCode"},
        data: {useCount: undefined},
      },
      {desc: {type: "create", model: "accountRole"}, data: {}},
      {desc: {type: "update", model: "account"}, data: {seats: undefined}},
    ],
  },
  "inviteCodes.applyExisting": {
    type: "void",
    implicit: () => [
      {
        desc: {type: "update", model: "userInviteCode"},
        data: {useCount: undefined},
      },
      {desc: {type: "create", model: "accountRole"}, data: {}},
      {desc: {type: "update", model: "account"}, data: {seats: undefined}},
    ],
  },

  "userTags.add": {
    type: "create",
    model: "userTag",
  },
  "userTags.delete": {
    type: "delete",
    model: "userTag",
  },
  "userTags.update": {
    type: "update",
    model: "userTag",
  },

  "bookmarks.addCards": {
    type: "create",
    model: "handCard",
  },
  "bookmarks.removeCards": {
    type: "delete",
    model: "handCard",
  },
  "bookmarks.setOrders": {
    type: "void",
    implicit: () => [
      {desc: {type: "create", model: "handCard"}, data: {}},
      {desc: {type: "update", model: "handCard"}, data: {sortIndex: undefined}},
    ],
  },
  "handQueue.addCardsToOwner": {
    type: "create",
    model: "queueEntry",
    implicit: ({cardIds}) => [
      ...handUpdates,
      {
        desc: {type: "update", model: "card"},
        data: {ids: cardIds, assigneeId: undefined},
      },
    ],
  },
  "handQueue.removeCards": {
    type: "delete",
    model: "queueEntry",
    implicit: () => [{desc: {type: "update", model: "queueEntry"}, data: {sortIndex: undefined}}],
  },
  "handQueue.setCardOrders": {
    type: "void",
    implicit: ({cardIds}) => [
      ...handUpdates,
      {
        desc: {type: "update", model: "card"},
        data: {ids: cardIds, assigneeId: undefined},
      },
    ],
  },
  "handQueue.setQueueSelection": {
    type: "void",
    implicit: () => [
      {desc: {type: "update", model: "queueSelection"}, data: {sortIndex: undefined}},
      {desc: {type: "create", model: "queueSelection"}, data: {}},
    ],
  },

  "notifications.dismissCardDiffs": {
    type: "void",
    implicit: ({userId, cardIds}) =>
      cardIds.map((cardId) => ({
        desc: {type: "delete", model: "cardDiffNotification"},
        data: {cardId, userId},
      })),
  },
  "notifications.dismissAutoFinishedTimeTrackingSegment": {
    type: "delete",
    model: "autoFinishedTimeTrackingSegment",
  },
  "notifications.forceDismissResolvables": {
    type: "void",
    implicit: ({userId, resolvableIds}) =>
      resolvableIds.map((resolvableId) => ({
        desc: {type: "delete", model: "resolvableNotification"},
        data: {resolvableId, userId},
      })),
  },
  "notifications.dismissResolvables": {
    type: "custom",
    fn: () => {},
    implicit: ({userId, resolvableIds}, {_isOptimistic}) =>
      _isOptimistic
        ? [
            {
              desc: {
                type: "custom",
                fn: ({modelCache, addOptimisticAction, mutationId, changes}) => {
                  resolvableIds.forEach((resId) => {
                    const key = JSON.stringify([userId, resId]);
                    const noti = modelCache.resolvableNotification[key];
                    if (!noti) return;
                    if (noti.value.isSnoozing) {
                      addOptimisticAction(
                        mutationId,
                        {
                          type: "update",
                          model: "resolvableNotification",
                        },
                        {
                          resolvableId: resId,
                          userId,
                          snoozeUntil: new Date(new Date().getTime() + 1000 * 3600 * 24 * 3),
                        },
                        true,
                        changes
                      );
                    } else {
                      addOptimisticAction(
                        mutationId,
                        {
                          type: "delete",
                          model: "resolvableNotification",
                        },
                        {resolvableId: resId, userId},
                        true,
                        changes
                      );
                    }
                  });
                },
              },
              data: {userId, resolvableIds},
            },
          ]
        : [],
  },
  "notifications.seenResolvableEntries": {
    type: "update",
    model: "resolvableNotification",
  },
  "notifications.snoozeResolvables": {
    type: "void",
    implicit: ({resolvableIds, userId, until}) =>
      resolvableIds.map((id) => ({
        desc: {type: "update", model: "resolvableNotification"},
        data: {
          userId,
          resolvableId: id,
          snoozeUntil: until,
        },
      })),
  },

  "watchings.addDeck": {
    type: "create",
    model: "deckSubscription",
  },
  "watchings.removeDeck": {
    type: "delete",
    model: "deckSubscription",
  },

  "watchings.addCard": {
    type: "create",
    model: "cardSubscription",
  },
  "watchings.removeCard": {
    type: "delete",
    model: "cardSubscription",
  },

  "search.save": {
    type: "create",
    model: "savedSearch",
  },
  "search.delete": {
    type: "delete",
    model: "savedSearch",
  },

  "files.update": {
    // action is only called by worker, no client directly
    type: "update",
    model: "file",
  },

  "releases.create": {
    // action is only called by release process, no client directly
    type: "create",
    model: "release",
  },

  "milestones.create": {
    type: "create",
    model: "milestone",
    convertDataForOptimistic: (data) => ({
      // convert "2016-11-23" to {year: 2016, month: 11, day: 23}
      ...data,
      date: dateStrToDay(data.date),
      startDate: data.startDate && dateStrToDay(data.startDate),
    }),
    implicit: () => [{desc: {type: "create", model: "milestoneProject"}, data: {}}],
  },
  "milestones.update": {
    type: "update",
    model: "milestone",
    convertDataForOptimistic: (data) => ({
      // convert "2016-11-23" to {year: 2016, month: 11, day: 23}
      ...data,
      date: data.date && dateStrToDay(data.date),
      startDate: data.startDate && dateStrToDay(data.startDate),
    }),
    implicit: ({handSyncEnabled}) => [
      {desc: {type: "create", model: "milestoneProject"}, data: {}},
      {desc: {type: "update", model: "card"}, data: {milestoneId: undefined}},
      {desc: {type: "update", model: "deck"}, data: {milestoneId: undefined}},
      ...(handSyncEnabled ? handUpdates : []),
    ],
  },
  "milestones.delete": {
    type: "delete",
    model: "milestone",
    implicit: ({id}) => [
      {desc: {type: "update", model: "milestone"}, data: {id, isDeleted: true}},
      {desc: {type: "create", model: "cardHistory"}, data: {}},
      {desc: {type: "update", model: "card"}, data: {milestoneId: undefined}},
      {desc: {type: "update", model: "deck"}, data: {milestoneId: undefined}},
    ],
  },
  "milestones.pin": {
    type: "void",
    implicit: ({milestoneId}) =>
      milestoneId
        ? [
            {desc: {type: "create", model: "pinnedMilestone"}, data: {}},
            {desc: {type: "update", model: "pinnedMilestone"}, data: {milestoneId, sprintId: null}},
          ]
        : [{desc: {type: "delete", model: "pinnedMilestone"}, data: {}}],
  },
  // called by the server only
  "milestones.updateStats": {
    type: "void",
    implicit: ({id}) => [
      {desc: {type: "update", model: "milestone"}, data: {id, stats: undefined}},
    ],
  },
  "milestoneProgress.update": {
    type: "void",
    implicit: (data) => [
      {desc: {type: "create", model: "milestoneProgress"}, data},
      {desc: {type: "update", model: "milestoneProgress"}, data: {...data, progress: undefined}},
    ],
  },

  "sprints.createConfig": {
    type: "create",
    model: "sprintConfig",
    implicit: () => [
      {desc: {type: "create", model: "sprintProject"}, data: {}},
      {desc: {type: "create", model: "sprint"}, data: {}},
    ],
  },
  "sprints.updateConfig": {
    type: "update",
    model: "sprintConfig",
    convertDataForOptimistic: (data) => ({
      ...data,
      stopOn: data.stopOn && dateStrToDay(data.stopOn),
    }),
    implicit: ({id}) => [
      {desc: {type: "create", model: "sprintProject"}, data: {}},
      {desc: {type: "create", model: "sprint"}, data: {}},
      {
        desc: {type: "update", model: "sprint"},
        data: {startDate: undefined, endDate: undefined, isDeleted: undefined, lockedAt: undefined},
      },
      {desc: {type: "update", model: "card"}, data: {sprintId: undefined}},
      {desc: {type: "update", model: "pinnedMilestone"}, data: {sprintId: id, milestoneId: null}},
    ],
  },
  "sprints.updateSprint": {
    type: "update",
    model: "sprint",
    implicit: ({id, autoMilestoneId, lockIn}) => {
      const updates = [];
      if (autoMilestoneId) {
        updates.push({
          desc: {type: "update", model: "card"},
          data: {milestoneId: undefined},
        });
      }
      if (lockIn) {
        updates.push({
          desc: {type: "update", model: "sprint"},
          data: {id, lockedAt: new Date()},
        });
      }
      return updates;
    },
  },
  "sprints.pinSprint": {
    type: "void",
    implicit: ({sprintId, ...rest}) =>
      sprintId
        ? [
            {desc: {type: "create", model: "pinnedMilestone"}, data: {}},
            {
              desc: {type: "update", model: "pinnedMilestone"},
              data: {sprintId, milestoneId: null, ...rest},
            },
          ]
        : [{desc: {type: "delete", model: "pinnedMilestone"}, data: {}}],
  },
  "sprints.updateStats": {
    type: "void",
    implicit: ({id}) => [{desc: {type: "update", model: "sprint"}, data: {id, stats: undefined}}],
  },
  "sprintProgress.update": {
    type: "void",
    implicit: (data) => [
      {desc: {type: "create", model: "sprintProgress"}, data},
      {desc: {type: "update", model: "sprintProgress"}, data: {...data, progress: undefined}},
    ],
  },
  "sprintConfigProgress.update": {
    type: "void",
    implicit: (data) => [
      {desc: {type: "create", model: "sprintConfigProgress"}, data},
      {desc: {type: "update", model: "sprintConfigProgress"}, data: {...data, progress: undefined}},
    ],
  },

  "hints.dismiss": {
    type: "create",
    model: "userDismissedHint",
  },
  "hints.undismiss": {
    type: "delete",
    model: "userDismissedHint",
  },

  "billing.addCreditCard": {
    type: "void",
  },
  "billing.createSubscription": {
    type: "void",
  },
  "billing.upgradeSubscription": {
    type: "void",
  },
  "billing.downgradeSubscription": {
    type: "void",
  },
  "billing.revokeSubscriptionDowngrade": {
    type: "void",
  },
  "billing.cancelSubscription": {
    type: "void",
  },
  "billing.revokeSubscriptionCancellation": {
    type: "void",
  },
  "billing.serverUpdate": {
    type: "void",
    implicit: ({accountId}) => [
      {desc: {type: "create", model: "stripeAccountSync"}, data: {}},
      {
        desc: {type: "update", model: "stripeAccountSync"},
        data: {
          accountId,
          status: undefined,
          euVatIdData: undefined,
          vatCountryCode: undefined,
          vatTaxPercentage: undefined,
          centsPerSeat: undefined,
          billingCycleStart: undefined,
          billingCycleEnd: undefined,
          grossActualBalance: undefined,
          grossBonusBalance: undefined,
          netGiftBalance: undefined,
          planType: undefined,
          paymentMethod: undefined,
          hasBeenCancelledAt: undefined,
          pendingPlanType: undefined,
          planName: undefined,
          offeringTrial: undefined,
        },
      },
    ],
  },
  "billing.addInvoice": {type: "create", model: "invoice"},

  "githubIntegration.addHook": {
    type: "update",
    model: "integration",
    implicit: ({id}) => [
      {desc: {type: "update", model: "integration"}, data: {id, userData: undefined}},
    ],
  },
  "githubIntegration.removeHook": {
    type: "update",
    model: "integration",
    implicit: ({id}) => [
      {desc: {type: "update", model: "integration"}, data: {id, userData: undefined}},
    ],
  },
  "githubIntegration.removeIntegration": {
    type: "delete",
    model: "integration",
  },
  "slackIntegration.update": {
    type: "update",
    model: "integration",
    implicit: ({id}) => [
      {desc: {type: "update", model: "integration"}, data: {id, userData: undefined}},
    ],
  },
  "slackIntegration.remove": {
    type: "delete",
    model: "integration",
    implicit: () => [{desc: {type: "delete", model: "integration"}, data: {}}],
  },
  "userReport.createSettings": {
    type: "create",
    model: "userReportSetting",
    implicit: () => [{desc: {type: "create", model: "userReportToken"}, data: {}}],
  },
  "userReport.updateSettings": {
    type: "update",
    model: "userReportSetting",
  },
  "userReport.regenerateSettingsToken": {
    type: "void",
  },
  "userReport.deleteSettings": {
    type: "delete",
    model: "userReportSetting",
  },
  "userReport.updateToken": {
    type: "update",
    model: "userReportToken",
  },

  "discordIntegration.updateGuild": {
    type: "update",
    model: "discordGuild",
  },
  "discordIntegration.createProjectNotification": {
    type: "create",
    model: "discordProjectNotification",
  },
  "discordIntegration.updateProjectNotification": {
    type: "update",
    model: "discordProjectNotification",
  },
  "discordIntegration.deleteProjectNotification": {
    type: "delete",
    model: "discordProjectNotification",
  },
  "discordIntegration.createSlashCommand": {
    type: "create",
    model: "discordSlashCommand",
  },
  "discordIntegration.updateSlashCommand": {
    type: "update",
    model: "discordSlashCommand",
  },
  "discordIntegration.deleteSlashCommand": {
    type: "delete",
    model: "discordSlashCommand",
  },
  "discordIntegration.removeGuild": {
    type: "delete",
    model: "discordGuild",
  },

  "projectSelections.set": {
    type: "create",
    model: "projectSelection",
    implicit: ({userId, projectIds}, {_isOptimistic}) =>
      _isOptimistic
        ? [
            {
              desc: {
                type: "custom",
                fn: ({addOptimisticAction, modelCache, mutationId, changes}) => {
                  const accountId = modelCache._root.value.account;
                  const psField = `projectSelections({"accountId":"${accountId}"})`;
                  const mutIds = projectIds.map((pId) => {
                    const mutId = `$opt$${pId}`;
                    addOptimisticAction(
                      mutationId,
                      {
                        type: "create",
                        model: "projectSelection",
                        onResolve: ({field: rawField, retVal}) => {
                          const value = retVal.projectSelections.find((ps) => ps.projectId === pId);
                          if (!value) return null;
                          const field =
                            {project: "projectId", user: "userId"}[rawField] || rawField;
                          return {value: value[field], retVal: value};
                        },
                      },
                      {id: mutId, project: pId, user: userId, account: accountId},
                      true,
                      changes
                    );
                    return mutId;
                  });
                  addOptimisticAction(
                    mutationId,
                    {
                      type: "update",
                      model: "user",
                      onResolve: ({field, retVal}) => {
                        if (field === psField) {
                          return {value: retVal.projectSelections.map((ps) => ps.id), retVal};
                        }
                      },
                    },
                    {id: userId, [psField]: mutIds},
                    true,
                    changes
                  );
                },
              },
              data: {userId, projectIds},
            },
          ]
        : [],
  },

  "projectSelections.add": {
    type: "create",
    model: "projectSelection",
  },
  "projectSelections.addMany": {
    type: "create",
    model: "projectSelection",
  },
  "projectSelections.remove": {
    type: "delete",
    model: "projectSelection",
  },
  "projectSelections.clear": {
    type: "delete",
    model: "projectSelection",
  },
  "projectSelections.selectSingle": {
    type: "delete",
    model: "projectSelection",
    implicit: (data) => [{desc: {type: "create", model: "projectSelection"}, data}],
  },

  "publicProjectMembership.create": {
    type: "create",
    model: "publicProjectMembership",
  },
  "publicProjectMembership.update": {
    type: "update",
    model: "publicProjectMembership",
  },
  "publicProjectMembership.delete": {
    type: "delete",
    model: "publicProjectMembership",
  },

  "affiliateCodes.create": {
    type: "create",
    model: "affiliateCode",
  },
  "affiliateCodes.update": {
    type: "update",
    model: "affiliateCode",
  },
  "affiliateCodes.delete": {
    type: "update",
    model: "affiliateCode",
    implicit: ({id}) => [
      {desc: {type: "update", model: "affiliateCode"}, data: {isDeleted: true, id}},
    ],
  },

  "timeTracking.start": {
    type: "create",
    model: "timeTrackingSegment",
    implicit: ({cardId, userId}) => [
      {desc: {type: "create", model: "activeTimeTracker"}, data: {cardId, userId}},
      {desc: {type: "update", model: "activeTimeTracker"}, data: {cardId, userId}},
      // might stop existing one
      {
        desc: {type: "update", model: "timeTrackingSegment"},
        data: {finishedAt: undefined},
      },
      {desc: {type: "delete", model: "timeTrackingSegment"}, data: {}},
      {
        desc: {type: "update", model: "timeTrackingSum"},
        data: {
          sumMs: undefined,
          runningModifyDurationMsBy: undefined,
          runningStartedAt: undefined,
        },
      },
      {desc: {type: "delete", model: "timeTrackingSum"}, data: {}},
      {desc: {type: "update", model: "card"}, data: {status: undefined}},
    ],
  },
  "timeTracking.stop": {
    type: "update",
    model: "timeTrackingSegment",
    implicit: ({id}) => [
      {desc: {type: "update", model: "timeTrackingSegment"}, data: {finishedAt: undefined, id}},
      {desc: {type: "delete", model: "timeTrackingSegment"}, data: {id}},
      {desc: {type: "update", model: "card"}, data: {status: undefined}},
      {
        desc: {type: "update", model: "timeTrackingSum"},
        data: {
          sumMs: undefined,
          runningModifyDurationMsBy: undefined,
          runningStartedAt: undefined,
        },
      },
      {desc: {type: "delete", model: "timeTrackingSum"}, data: {}},
    ],
  },
  "timeTracking.setModifyDuration": {
    type: "update",
    model: "timeTrackingSegment",
    implicit: ({id}) => [
      {
        desc: {type: "update", model: "timeTrackingSum"},
        data: {sumMs: undefined, runningModifyDurationMsBy: undefined},
      },
      {
        desc: {type: "update", model: "timeTrackingSegment"},
        data: {id, autoFinishedState: undefined},
      },
    ],
  },
  "timeTracking.create": {
    type: "create",
    model: "timeTrackingSegment",
    implicit: ({cardId, userId}) => [
      {
        desc: {type: "create", model: "timeTrackingSum"},
        data: {},
      },
      {
        desc: {type: "update", model: "timeTrackingSum"},
        data: {userId, cardId, sumMs: undefined},
      },
      {
        desc: {type: "update", model: "timeTrackingSum"},
        data: {userId: null, cardId, sumMs: undefined},
      },
    ],
  },
  "timeTracking.delete": {
    type: "delete",
    model: "timeTrackingSegment",
    implicit: () => [
      {
        desc: {type: "update", model: "timeTrackingSum"},
        data: {sumMs: undefined},
      },
    ],
  },
  "timeTracking.close": {
    type: "delete",
    model: "activeTimeTracker",
  },

  "votes.vote": {
    type: "create",
    model: "cardUpvote",
    implicit: ({cardId}) => [
      {
        desc: {type: "update", model: "card"},
        data: {id: cardId, meta: undefined},
      },
    ],
  },
  "votes.unvote": {
    type: "delete",
    model: "cardUpvote",
    implicit: ({cardId}) => [
      {
        desc: {type: "update", model: "card"},
        data: {id: cardId, meta: undefined},
      },
    ],
  },
  "cardOrders.addAfter": {
    type: "void",
    implicit: ({cardIds, context: sortCtx, label}) => [
      {
        desc: {type: "create", model: "cardOrder"},
        data: {},
      },
      {desc: {type: "create", model: "activity"}, data: {cardId: undefined}},
      ...cardIds.map((cardId) => ({
        desc: {type: "update", model: "cardOrder"},
        data: {cardId, context: sortCtx, sortValue: undefined, label},
      })),
    ],
  },
  "cardOrders.addBefore": {
    type: "void",
    implicit: ({cardIds, context: sortCtx, label}) => [
      {
        desc: {type: "create", model: "cardOrder"},
        data: {},
      },
      {desc: {type: "create", model: "activity"}, data: {cardId: undefined}},
      ...cardIds.map((cardId) => ({
        desc: {type: "update", model: "cardOrder"},
        data: {cardId, context: sortCtx, sortValue: undefined, label},
      })),
    ],
  },
  "cardOrders.updateLabel": {
    type: "void",
    implicit: ({cardIds, context: sortCtx, label}) =>
      cardIds.map((cardId) => ({
        desc: {type: "update", model: "cardOrder"},
        data: {cardId, context: sortCtx, label},
      })),
  },
  "lastSeenUpvotes.justSeen": {
    type: "create",
    model: "lastSeenCardUpvote",
    implicit: ({userId, accountId}) => [
      {
        desc: {type: "update", model: "lastSeenCardUpvote"},
        data: {userId, accountId, lastSeenAt: undefined},
      },
    ],
  },

  "visionBoard.createBoard": {
    type: "create",
    model: "visionBoard",
  },
  "visionBoard.updateBoard": {
    type: "update",
    model: "visionBoard",
    implicit: ({projectIds}) =>
      projectIds
        ? [
            {
              desc: {type: "create", model: "visionBoardProject"},
              data: {},
            },
            {
              desc: {type: "delete", model: "visionBoardProject"},
              data: {},
            },
          ]
        : [],
  },
  "visionBoard.deleteBoard": {
    type: "delete",
    model: "visionBoard",
  },

  "onboarding.progress": {
    type: "void",
  },
  "wizards.progress": {
    type: "update",
    model: "wizard",
  },
  "wizards.skip": {
    type: "void",
    implicit: ({id}) => [
      {
        desc: {type: "update", model: "wizard"},
        data: {id, finishedAt: undefined},
      },
      {desc: {type: "create", model: "wizard"}, data: {}},
    ],
  },
  "wizards.finish": {
    type: "void",
    implicit: ({id}) => [
      {
        desc: {type: "update", model: "wizard"},
        data: {id, finishedAt: undefined},
      },
      {desc: {type: "create", model: "wizard"}, data: {}},
    ],
  },
};

export const api = createApi(descriptions, fetcher, mutations, dispatcher);
