import {useState, useRef, useMemo, useEffect, forwardRef, Component} from "react";
import {useDragItem} from "@codecks/dnd";
import {useNotifications} from "./useNotifications";
import {useSearchStore} from "../search/useSearch";
import {XCol, XPush} from "../../components/xui";
import Avatar from "../../components/Avatar";
import {useTransition, animated, useSpring} from "react-spring";
import StackLabel from "./StackLabel";
import NotificationPill, {getDismissIdsByType, NonShownPill} from "./NotificationPill";
import XTextButton from "../../components/xui/XTextButton";
import useNotificationStacks from "./useNotificationStacks";
import {api} from "../../lib/api";
import {useReveal, springConfigs, cx} from "@cdx/common";
import {postError} from "../../lib/error-logger";
import {notiStyles, panelWidth} from "./notifications.css";
import {singleKeyLocalStorage} from "../../lib/storage";
import {cardUrlState, toCardPath} from "../../layouts/arena-layout/createArenaContext";
import {
  DSIconBell,
  DSIconCollapse,
  DSIconConversation,
  DSIconExpand,
  DSIconEye,
  DSIconStopWatch,
} from "@cdx/ds";
import {EXPERIMENTS, useExperiment} from "../../lib/ab-experiments/experiments";
import {useSmartSidebarOpenStore} from "../../components/SmartSidebar";
import {FEATURE_FLAGS, hasFeatureFlag} from "../../components/useFeatureFlag";

const allOpenStorage = singleKeyLocalStorage("default-notification-all-open");

const NOTI_HEIGHT = 55;
const LABEL_HEIGHT = 55;
const NON_SHOWN_HEIGHT = 40;

const AnimatedNotificationPill = animated(NotificationPill);

const useOpenStyles = (isOpen) => {
  return useReveal(isOpen, {
    from: {opacity: 0, transform: "scaleX(0.25) scaleY(0.75)"},
    enter: {opacity: 1, transform: "scaleX(1) scaleY(1)"},
    leave: {opacity: 0, transform: "scaleX(0.25) scaleY(0.75)"},
    config: springConfigs.quick,
  });
};

const useIsHovered = () => {
  const [hovered, setHovered] = useState();
  const timeoutRef = useRef(null);
  const listeners = useMemo(
    () => ({
      onMouseEnter: () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        setHovered(true);
      },
      onMouseLeave: () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setHovered(false);
          timeoutRef.current = null;
        }, 250);
      },
    }),
    []
  );
  useEffect(
    () => () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    []
  );
  return [hovered, listeners];
};

const StackContainer = forwardRef((props, ref) => {
  const {
    label,
    isForceOpen,
    toggleForceOpen,
    type,
    icon,
    style,
    totalHeight,
    listeners,
    hovered,
    openReveal,
    children,
    allNotifications,
    userId,
    root,
  } = props;

  const v2Active = hasFeatureFlag(root.account, FEATURE_FLAGS.notificationV2);

  const handleDismissAll = () => {
    const allIds = {res: [], cardDiff: []};
    allNotifications.forEach((n) => {
      const {type: dType, ids} = getDismissIdsByType[n.type](n);
      allIds[dType].push(...ids);
    });
    return Promise.all([
      ...(allIds.res.length
        ? [
            v2Active
              ? api.mutate.notifications.forceDismissResolvables({
                  resolvableIds: allIds.res,
                  userId,
                })
              : api.mutate.notifications.dismissResolvables({resolvableIds: allIds.res, userId}),
          ]
        : []),
      ...(allIds.cardDiff.length
        ? [api.mutate.notifications.dismissCardDiffs({cardIds: allIds.cardDiff, userId})]
        : []),
    ]);
  };

  return (
    <XCol
      className={notiStyles.stackContainer}
      style={{...style, width: hovered || isForceOpen ? panelWidth + 35 : 70}}
      absolute
      {...listeners}
      align="end"
      ref={ref}
    >
      <XCol style={{height: totalHeight + 5}} relative>
        {children}
      </XCol>
      <StackLabel
        label={label}
        icon={icon}
        isForceOpen={isForceOpen}
        openReveal={openReveal}
        isHovered={hovered}
        onClick={() => toggleForceOpen(type)}
        canDismiss={allNotifications.every((n) => n.type !== "dueCard")}
        onDismiss={handleDismissAll}
      />
    </XCol>
  );
});

const Stack = forwardRef((props, ref) => {
  const {
    shownNotifications,
    allNotifications,
    label,
    isForceOpen,
    toggleForceOpen,
    type,
    icon,
    userId,
    account,
    getCardUrl,
    style,
    root,
    nonShownCount,
    showFacesExperiment,
  } = props;

  const totalHeight = shownNotifications.length * NOTI_HEIGHT;
  const [hovered, listeners] = useIsHovered();
  const openReveal = useOpenStyles(hovered || isForceOpen);
  let height = totalHeight + (nonShownCount ? NON_SHOWN_HEIGHT : 0);
  const keysToNotisRef = useRef({});
  shownNotifications.forEach((n) => (keysToNotisRef.current[n.key] = n));
  const transition = useTransition(
    shownNotifications.map((data) => ({notiInfo: data, xy: [0, -(height -= NOTI_HEIGHT)]})),
    {
      key: (item) => item.notiInfo.key,
      from: {opacity: 0},
      leave: ({xy}) => ({opacity: 0.001, xy: [9, xy[1]]}),
      enter: ({xy}) => ({opacity: 1, xy}),
      update: ({xy}) => ({xy}),
      config: springConfigs.quick,
    }
  );

  const nonShownTransition = useTransition(nonShownCount, {
    key: (i) => Boolean(i),
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0.001},
    config: springConfigs.quick,
  });

  return (
    <StackContainer
      label={label}
      isForceOpen={isForceOpen}
      toggleForceOpen={toggleForceOpen}
      type={type}
      icon={icon}
      style={style}
      totalHeight={totalHeight}
      listeners={listeners}
      hovered={hovered}
      openReveal={openReveal}
      allNotifications={allNotifications}
      userId={userId}
      ref={ref}
      root={root}
    >
      {transition(({xy, ...rest}, item) => (
        <AnimatedNotificationPill
          style={{
            transform: xy.to((x, y) => `translate3d(${x}px,${y}px,0)`),
            ...rest,
          }}
          getCardUrl={getCardUrl}
          notiInfo={item.notiInfo}
          openReveal={openReveal}
          account={account}
          userId={userId}
          root={root}
          showFacesExperiment={showFacesExperiment}
        />
      ))}
      {nonShownTransition(
        (pillStyle, count) => count > 0 && <NonShownPill style={pillStyle} count={count} />
      )}
    </StackContainer>
  );
});

const AnimatedStack = animated(Stack);

const initialPanels = () => {
  const forceAll = allOpenStorage.get() === true;
  return {conv: forceAll, owner: forceAll, subbed: forceAll, due: forceAll};
};

const useAllOpen = () => {
  const [openPanels, setOpen] = useState(initialPanels);
  const allOpen = Object.values(openPanels).every(Boolean);

  return {
    openPanels,
    allOpen,
    toggleAll: () => {
      allOpenStorage.set(!allOpen);
      setOpen({conv: !allOpen, owner: !allOpen, subbed: !allOpen, due: !allOpen});
    },
    togglePanel: (name) => {
      setOpen((prev) => ({...prev, [name]: !prev[name]}));
    },
  };
};

const useHeight = (nextHeight) => {
  const currentHeightRef = useRef(nextHeight);
  const diff = currentHeightRef.current - nextHeight;
  const [props, spring] = useSpring(() => ({height: currentHeightRef.current}));

  const timeoutIdRef = useRef(null);
  if (diff > 0) {
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    // FIXME: setting `.current` here is unsafe as if the component might get
    // rerendered due to Suspense and the timout won't be cleared
    timeoutIdRef.current = setTimeout(() => {
      timeoutIdRef.current = null;
      currentHeightRef.current = nextHeight;
      spring.start({height: currentHeightRef.current});
    }, 150);
  } else {
    // increased in size
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    currentHeightRef.current = nextHeight;
    spring.start({height: currentHeightRef.current});
  }

  return props;
};

const getHandCardUrl = ({card, targetPanel, resolvableId, latestSeenEntryId}) => {
  const {accountSeq, title} = card;
  return {
    pathname: `/card/${toCardPath({accountSeq, title})}`,
    state: cardUrlState({targetPanel, resolvableId, latestSeenEntryId}),
  };
};

const stackInfos = [
  {
    type: "autoFinishedTimeTrackingSegments",
    label: "Time Tracking",
    icon: () => <DSIconStopWatch size={20} />,
  },
  {type: "due", label: "Due Cards", icon: () => <DSIconBell size={20} />},
  {type: "conv", label: "Conversations", icon: () => <DSIconConversation size={20} />},
  {
    type: "owner",
    label: "Cards you own",
    icon: ({root}) => <Avatar user={root.loggedInUser} className={notiStyles.ownerAvatar} />,
  },
  {type: "subbed", label: "Cards you watch", icon: () => <DSIconEye size={20} />},
];

const InnerNotifications = ({root, notifications, location}) => {
  const {openPanels, allOpen, toggleAll, togglePanel} = useAllOpen();
  const userId = root.loggedInUser && root.loggedInUser.$meta.get("id", null);
  const {stacks, notisWithoutType} = useNotificationStacks({notifications, userId});
  const noTypeKey = notisWithoutType
    .map((n) => n.cardId)
    .filter(Boolean)
    .join("-");
  const dismissWithoutTypeRef = useRef();
  useEffect(() => {
    dismissWithoutTypeRef.current = () =>
      api.mutate.notifications.dismissCardDiffs({
        cardIds: notisWithoutType.map((n) => n.cardId),
        userId,
      });
  });
  useEffect(() => {
    if (noTypeKey && userId) dismissWithoutTypeRef.current();
  }, [noTypeKey, userId]);
  const stackProps = [];
  const maxLimit = 10;
  stackInfos.forEach(({type, label, icon}) => {
    const notis = stacks[type];
    if (!notis.length) return;
    const shownNotis = notis.length <= maxLimit ? notis : notis.slice(0, maxLimit);
    const nonShownCount = notis.length - shownNotis.length;
    stackProps.push({
      props: {
        type,
        shownNotifications: shownNotis,
        allNotifications: notis,
        label,
        icon: icon({root}),
        nonShownCount,
      },
      height:
        LABEL_HEIGHT + shownNotis.length * NOTI_HEIGHT + (nonShownCount ? NON_SHOWN_HEIGHT : 0),
    });
  });

  const keysToProps = useRef({});
  stackProps.forEach(({props}) => (keysToProps.current[props.type] = props));

  const totalHeight = stackProps.reduce((s, d) => s + d.height, 0);
  const heightProps = useHeight(totalHeight);
  let height = totalHeight;
  const transitionStacks = useTransition(
    stackProps.map(({props, height: stackHeight}) => ({
      type: props.type,
      y: -(height -= stackHeight),
    })),
    {
      key: (item) => item.type,
      from: {opacity: 0},
      leave: {opacity: 0.001},
      enter: ({y}) => ({y, opacity: 1}),
      update: ({y}) => ({y}),
    }
  );

  const revealAllOpen = useReveal(stackProps.length > 0, {config: springConfigs.quick});
  const showFacesExperiment = useExperiment(EXPERIMENTS.NotifcationsWithFaces, root.account);

  return (
    <XCol className={notiStyles.outer} sp={1} data-cdx-context="Notifications">
      <XPush />
      <XCol as={animated.div} style={heightProps} relative>
        {transitionStacks(({y, ...rest}, {type}) => (
          <AnimatedStack
            {...keysToProps.current[type]}
            style={{transform: y.to((realY) => `translate3d(0,${realY}px,0)`), ...rest}}
            isForceOpen={openPanels[type]}
            toggleForceOpen={togglePanel}
            userId={userId}
            root={root}
            getCardUrl={getHandCardUrl}
            showFacesExperiment={showFacesExperiment}
          />
        ))}
      </XCol>
      {revealAllOpen((props) => (
        <animated.div
          className={notiStyles.toggleAllSpacer}
          style={{
            opacity: props.value,
            transform: props.value.to((v) => `scale(${v * 0.75 + 0.25})`),
          }}
        >
          <div className={notiStyles.toggleAllContainer}>
            <XTextButton
              size="lg"
              square
              active={allOpen}
              onClick={toggleAll}
              color="dimWhite"
              className={cx(
                notiStyles.toggleAllButton.base,
                allOpen && notiStyles.toggleAllButton.active
              )}
            >
              {allOpen ? <DSIconCollapse size={16} /> : <DSIconExpand size={16} />}
            </XTextButton>
          </div>
        </animated.div>
      ))}
    </XCol>
  );
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    postError(error, JSON.stringify({message: error.message, ...errorInfo}));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <XCol absolute className={notiStyles.errorContainer}>
          <XTextButton
            tooltip="An error occurred when rendering notifcations. Please reload this window."
            size="lg"
            square
          >
            <span role="img" aria-label="explosion">
              💥
            </span>
          </XTextButton>
        </XCol>
      );
    }

    return this.props.children;
  }
}

const Notifications = ({root, location}) => {
  const isFocussed = useSearchStore((s) => s.isFocussed);
  const sidebarOpen = useSmartSidebarOpenStore((s) => Boolean(s.showEl));
  const notifications = useNotifications();
  const cardDragInfo = useDragItem("CARD");

  return (
    !sidebarOpen &&
    !isFocussed &&
    !cardDragInfo && (
      <ErrorBoundary>
        <InnerNotifications root={root} notifications={notifications} location={location} />
      </ErrorBoundary>
    )
  );
};

export default Notifications;
