import {api} from "./api";
import {waitForResultPromise} from "./wait-for-result";
import confirm from "./confirm";
import MilestoneLabel from "../pages/milestones/MilestoneLabel";
import {DISCORD_USER_ID} from "./ids";
import {projectsUsers, accountUsers, pluralize} from "./utils";
import {useInstance, useRoot} from "./mate/mate-utils";
import {hasPermissionToManageUser} from "./permissions";
import {Card, CardId} from "../cdx-models/Card";
import {Project, ProjectId} from "../cdx-models/Project";
import {User, UserId} from "../cdx-models/User";
import {Milestone, MilestoneId} from "../cdx-models/Milestone";
import {Box, Col, css, DSButton, Row, Text} from "@cdx/ds";
import {Root} from "../cdx-models/Root";
import DSAvatar from "../components/DSAvatar/DSAvatar";
import {Deck, DeckId} from "../cdx-models/Deck";
import {isCardBlocked, isCardInReview} from "./card-status-utils";
import {SprintConfig, SprintConfigId} from "../cdx-models/SprintConfig";

type Modification =
  | {
      type: "unassign";
      card: Card;
      data: User;
    }
  | {
      type: "milestone";
      card: Card;
      data: Milestone;
    }
  | {
      type: "sprintConfig";
      card: Card;
      data: SprintConfig;
    }
  | {
      type: "optOut";
      card: Card;
      data: User;
    };

const modificationReasons = (cards: Card[], targetProject: Project) => {
  const root = api.getRoot();
  const activeUserIds = new Set(
    accountUsers(root.account, {allowObservers: false}).map((u: User) => u.id)
  );
  const validUserIdSet = new Set(
    projectsUsers(root.account, [targetProject], {allowObservers: true}).map((u: User) => u.id)
  );
  validUserIdSet.add(DISCORD_USER_ID);
  const validMilestoneIds = new Set(
    targetProject.milestoneProjects.map(
      (mp) => (mp as any as {milestoneId: MilestoneId}).milestoneId
    )
  );
  const validSprintConfigIds = new Set(
    targetProject.sprintProjects.map(
      (mp) => (mp as any as {sprintConfigId: SprintConfigId}).sprintConfigId
    )
  );
  const modifications: Modification[] = [];
  cards.forEach((card) => {
    if (!card || !card.deck) return;
    if (card.deck.project !== targetProject) {
      if (
        card.assignee &&
        !validUserIdSet.has(card.assignee.id) &&
        activeUserIds.has(card.assignee.id)
      ) {
        modifications.push({type: "unassign", card: card, data: card.assignee});
      }
      if (card.milestone && !validMilestoneIds.has(card.milestone.id as MilestoneId)) {
        modifications.push({type: "milestone", card: card, data: card.milestone});
      }
      const sprint = card.sprint;
      if (sprint && !validSprintConfigIds.has(sprint.sprintConfig.id as SprintConfigId)) {
        modifications.push({type: "sprintConfig", card: card, data: sprint.sprintConfig});
      }
      const removeUsers = new Set<User>();
      card.$meta.find("resolvables", {isClosed: false}).forEach((r) =>
        r.participants.forEach((p) => {
          if (!validUserIdSet.has(p.user.id)) {
            removeUsers.add(p.user);
          }
        })
      );
      removeUsers.forEach((user) => {
        modifications.push({type: "optOut", card: card, data: user});
      });
    }
  });
  return modifications;
};

const groupReasons = (reasons: Modification[]) => {
  const byMsId: Record<MilestoneId, {ms: Milestone; count: number}> = {};
  const bySprintConfigId: Record<SprintConfigId, {sprintConfig: SprintConfig; count: number}> = {};
  const byUserId: Record<UserId, {user: User; convoCount: number; unassignCount: number}> = {};
  reasons.forEach(({type, data}) => {
    switch (type) {
      case "milestone": {
        const msId = data.id as MilestoneId;
        if (!byMsId[msId]) byMsId[msId] = {ms: data, count: 0};
        byMsId[msId].count += 1;
        break;
      }
      case "sprintConfig": {
        const sprintConfigId = data.id as SprintConfigId;
        if (!bySprintConfigId[sprintConfigId])
          bySprintConfigId[sprintConfigId] = {sprintConfig: data, count: 0};
        bySprintConfigId[sprintConfigId].count += 1;
        break;
      }
      default: {
        const userId = data.id as UserId;
        if (!byUserId[userId]) byUserId[userId] = {user: data, convoCount: 0, unassignCount: 0};
        if (type === "unassign") {
          byUserId[userId].unassignCount += 1;
        } else {
          byUserId[userId].convoCount += 1;
        }
      }
    }
  });
  return {
    msReasons: Object.values(byMsId),
    sprintConfigReasons: Object.values(bySprintConfigId),
    userReasons: Object.values(byUserId),
  };
};

type MsReasonProps = {
  ms: Milestone;
  count: number;
  targetProject: Project;
  canModifyMilestoneProjects: boolean | null;
  root: Root;
};

const MsReason = ({ms, count, targetProject, canModifyMilestoneProjects, root}: MsReasonProps) => {
  const msProjectIds = ms.milestoneProjects.map(
    (mp) => (mp as any as {projectId: ProjectId}).projectId
  );
  const {isGlobal} = ms;
  const onAddProjectToMs = () =>
    api.mutate.milestones.update({
      id: ms.id,
      isGlobal,
      projectIds: [...msProjectIds, targetProject.id],
    });
  return (
    <Row sp="16px" align="start">
      <Col sp="8px">
        <Text type="label12" color="primary">
          Milestone is specific to old project
        </Text>
        <Row align="baseline" sp="12px">
          <Text type="label14">
            <MilestoneLabel ms={ms} account={root.account} />
          </Text>
          <Text type="label12" color="secondary">
            {pluralize(count, "Card")} affected
          </Text>
        </Row>
      </Col>
      <Box ml="auto">
        {canModifyMilestoneProjects ? (
          <DSButton variant="secondary" size="sm" onClick={onAddProjectToMs}>
            Add '{targetProject.name}' project to milestone '{ms.name}'
          </DSButton>
        ) : (
          <Text type="prose12" color="secondary">
            Ask admin to add '{targetProject.name}' milestone '{ms.name}'
          </Text>
        )}
      </Box>
    </Row>
  );
};

type SprintReasonProps = {
  sprintConfig: SprintConfig;
  count: number;
  targetProject: Project;
  canModifyMilestoneProjects: boolean | null;
  root: Root;
};

const SprintReason = ({
  sprintConfig,
  count,
  targetProject,
  canModifyMilestoneProjects,
  root,
}: SprintReasonProps) => {
  const msProjectIds = sprintConfig.sprintProjects.map(
    (mp) => (mp as any as {projectId: ProjectId}).projectId
  );
  const {isGlobal} = sprintConfig;
  const onAddProjectToMs = () =>
    api.mutate.sprints.updateConfig({
      id: sprintConfig.id,
      isGlobal,
      projectIds: [...msProjectIds, targetProject.id],
    });
  return (
    <Row sp="16px" align="start">
      <Col sp="8px">
        <Text type="label12" color="primary">
          Run Config is specific to old project
        </Text>
        <Row align="baseline" sp="12px">
          <Text type="label14">{sprintConfig.name}</Text>
          <Text type="label12" color="secondary">
            {pluralize(count, "Card")} affected
          </Text>
        </Row>
      </Col>
      <Box ml="auto">
        {canModifyMilestoneProjects ? (
          <DSButton variant="secondary" size="sm" onClick={onAddProjectToMs}>
            Add '{targetProject.name}' project to '{sprintConfig.name}'
          </DSButton>
        ) : (
          <Text type="prose12" color="secondary">
            Ask admin to add '{targetProject.name}' to '{sprintConfig.name}'
          </Text>
        )}
      </Box>
    </Row>
  );
};

const UserReason = ({
  user,
  unassignCount,
  convoCount,
  canManageUsers,
  targetProject,
}: {
  user: User;
  unassignCount: number;
  convoCount: number;
  canManageUsers: boolean | null;
  targetProject: Project;
}) => {
  const onGiveAccess = () =>
    api.mutate.roles.addProjectAccess({userId: user.id, projectId: targetProject.id});
  return (
    <Row sp="16px" align="start">
      <Col sp="8px">
        <Text type="label12" color="primary">
          User has no access to target project
        </Text>
        <Row align="center" sp="12px">
          <DSAvatar user={user} size={16} />
          <Text type="label12" color="primary">
            {user.name}
          </Text>
          {unassignCount > 0 && (
            <Text type="label12" color="secondary">
              assigned to {unassignCount} card{unassignCount === 1 ? "" : "s"}
            </Text>
          )}
          {convoCount > 0 && (
            <Text type="label12" color="secondary">
              involved in conversations in {convoCount} card{convoCount === 1 ? "" : "s"}
            </Text>
          )}
        </Row>
      </Col>
      <Box ml="auto">
        {canManageUsers ? (
          <DSButton variant="secondary" size="sm" onClick={onGiveAccess}>
            Give {user.name} access to '{targetProject.name}'
          </DSButton>
        ) : (
          <Text type="label12" color="secondary">
            Ask admin to give {user.name} access to '{targetProject.name}'
          </Text>
        )}
      </Box>
    </Row>
  );
};

const ProjectMoveDialog = ({
  cardIds,
  targetProjectId,
  onCancel,
  onConfirm,
  target,
}: {
  cardIds: CardId[];
  targetProjectId: ProjectId;
  onCancel: () => void;
  onConfirm: () => void;
  target: string;
}) => {
  const targetProject = useInstance("project", targetProjectId);
  const root = useRoot();
  const cards = cardIds.map((id) => api.getModel({modelName: "card", id}) as Card);
  const reasons = modificationReasons(cards, targetProject);
  const {msReasons, userReasons, sprintConfigReasons} = groupReasons(reasons);
  const affected = [];
  if (msReasons.length) affected.push("Milestones");
  if (sprintConfigReasons.length) affected.push("Run Configs");
  if (userReasons.length) affected.push("Users");
  const canManageUsers = hasPermissionToManageUser(root);
  const canModifyMilestoneProjects = hasPermissionToManageUser(root);

  return (
    <Col sp="24px">
      <Col sp="24px">
        {msReasons.map((r) => (
          <MsReason
            key={`ms-${r.ms.id}`}
            ms={r.ms}
            count={r.count}
            canModifyMilestoneProjects={canModifyMilestoneProjects}
            targetProject={targetProject}
            root={root}
          />
        ))}
        {sprintConfigReasons.map((r) => (
          <SprintReason
            key={`s-${r.sprintConfig.id}`}
            sprintConfig={r.sprintConfig}
            count={r.count}
            canModifyMilestoneProjects={canModifyMilestoneProjects}
            targetProject={targetProject}
            root={root}
          />
        ))}
        {userReasons.map((r) => (
          <UserReason
            key={`u-${r.user.id}`}
            user={r.user}
            unassignCount={r.unassignCount}
            convoCount={r.convoCount}
            canManageUsers={canManageUsers}
            targetProject={targetProject}
          />
        ))}
        {affected.length === 0 && (
          <Text type="label14" color="primary">
            All issues resolved!
          </Text>
        )}
      </Col>
      <Row sp="32px" align="center">
        <DSButton variant="secondary" onClick={onCancel}>
          Close
        </DSButton>
        <DSButton variant="primary" size="lg" onClick={onConfirm} className={css({ml: "auto"})}>
          Move {target}
          {affected.length > 0 && ` and remove ${affected.join(" and ")} from affected Cards`}
        </DSButton>
      </Row>
    </Col>
  );
};

const confirmProjectMoveActions = (
  cardIds: CardId[],
  targetProjectId: ProjectId,
  targetLabel: string
) => {
  return confirm({
    title: `Access issues for target project`,
    comp: ProjectMoveDialog,
    compProps: {
      cardIds,
      targetProjectId: targetProjectId,
      target: targetLabel,
    },
  });
};

export const canDeckBeMoved = (deckId: DeckId, targetProjectId: ProjectId) => {
  return waitForResultPromise(() => {
    const deck: Deck = api.getModel({modelName: "deck", id: deckId});
    if (deck.project.id === targetProjectId) return true;
    const cards = deck.$meta.find("cards", {visibility: "default"});
    const targetProject = api.getModel({modelName: "project", id: targetProjectId});
    const projectReasons = modificationReasons(cards, targetProject);
    return {projectReasons, cardIds: cards.map((c) => c.cardId as CardId)};
  }).then((res) => {
    if (res === true) return true;
    const {projectReasons, cardIds} = res;
    if (projectReasons.length > 0) {
      return confirmProjectMoveActions(cardIds, targetProjectId, "Deck");
    } else {
      return true;
    }
  });
};

type DeckIssue = {type: "nonDocCards" | "docCards" | "openConvoCards"; cardIds: CardId[]};
const canCardsBeMoveToDeck = (cards: Card[], deck: Deck): DeckIssue | null => {
  const allowedCardTypes = deck.allowedCardTypes;
  const onlyDocCards = allowedCardTypes.length === 1 && allowedCardTypes[0] === "doc";
  const noDocCards = !allowedCardTypes.includes("doc");
  const noTaskCards = !allowedCardTypes.includes("task");
  if (onlyDocCards) {
    const nonDocCards = cards.filter((c) => !c.isDoc);
    if (nonDocCards.length > 0)
      return {type: "nonDocCards", cardIds: nonDocCards.map((c) => c.cardId as CardId)};
  } else if (noDocCards) {
    const docCards = cards.filter((c) => c.isDoc);
    if (docCards.length > 0)
      return {type: "docCards", cardIds: docCards.map((c) => c.cardId as CardId)};
  }
  if (noTaskCards) {
    const convoCards = cards.filter((c) => isCardBlocked(c) || isCardInReview(c));
    if (convoCards.length > 0) {
      return {type: "openConvoCards", cardIds: convoCards.map((c) => c.cardId as CardId)};
    }
  }
  return null;
};

const DeckIssueDialog = ({issue, onCancel}: {issue: DeckIssue; onCancel: () => void}) => {
  const {type} = issue;
  const getLabel = () => {
    if (type === "docCards") {
      return "Deck does not allow Doc Cards.";
    } else if (type === "nonDocCards") {
      return "Deck only allows Doc Cards.";
    } else if (type === "openConvoCards") {
      return "Deck does not allow Cards with open review or block conversations";
    }
  };
  return (
    <Col sp="24px">
      <Text type="prose16" color="primary">
        {getLabel()}
      </Text>
      <Row sp="32px" align="center">
        <DSButton variant="primary" size="lg" onClick={onCancel} className={css({ml: "auto"})}>
          Okay
        </DSButton>
      </Row>
    </Col>
  );
};

export const moveCardsToDeck = (cardIds: CardId[], deckId: DeckId | null) => {
  if (!deckId) return Promise.resolve(true);
  return waitForResultPromise(() => {
    const deck: Deck = api.getModel({modelName: "deck", id: deckId});
    const cards = cardIds.map((id) => api.getModel({modelName: "card", id}) as Card);
    const deckIssue = canCardsBeMoveToDeck(cards, deck);
    if (deckIssue) return {projectReasons: [], projectId: deck.project.id as ProjectId, deckIssue};
    const projectReasons = modificationReasons(cards, deck.project);
    return {projectReasons, projectId: deck.project.id as ProjectId, deckIssue};
  })
    .then(({deckIssue, projectReasons, projectId}) => {
      if (deckIssue) {
        return confirm({
          title: `Invalid target Deck`,
          comp: DeckIssueDialog,
          compProps: {
            issue: deckIssue,
          },
        }).then(() => false);
      }
      if (projectReasons.length > 0) {
        return confirmProjectMoveActions(
          cardIds,
          projectId,
          cardIds.length === 1 ? "Card" : "Cards"
        );
      } else {
        return true;
      }
    })
    .then((ok) =>
      ok ? api.mutate.cards.bulkUpdate({deckId, ids: cardIds}).then(() => true) : false
    );
};
